import { createSlice } from "@reduxjs/toolkit";

export const HealthSlice = createSlice({
    name: "Health",
    initialState: {
        HealthData: [],
        KemtaiHealthData:{}
    },
    reducers: {
        setHealthData: (state, action) => {
            state.HealthData = action.payload;
        },
        setKemtaiHealthData: (state, action) => {
            state.KemtaiHealthData = action.payload;
        },

    },
});

export const { setHealthData, setKemtaiHealthData } = HealthSlice.actions;


export const HealthReducer = HealthSlice.reducer;