import moment from "moment";

export const generateTimeline = (startTimestamp, endTimestamp) => {
    const StartDate = moment(startTimestamp * 1000);
    const EndDate = moment(endTimestamp * 1000);

    const millisecondsInDay = 86400000; // 24 * 60 * 60 * 1000
    const startDate = new Date(startTimestamp * 1000); // Convert to milliseconds
    const endDate = new Date(endTimestamp * 1000); // Convert to milliseconds
    const thirtyDaysInMilliseconds = 30 * millisecondsInDay;

    const getCurrentTimeline = (timeline, date) => {
        const currentDate = new Date();
        let currentTimeline = timeline[0]; // Default to first timeline

        // Find the timeline that the current date falls within
        for (let i = 0; i < date.length; i++) {
            const [start, end] = date[i].split(",").map((d) => new Date(d));
            if (start <= currentDate && currentDate <= end) {
                currentTimeline = timeline[i];
                break;
            }
        }

        return currentTimeline;
    };

    let timeline = [];
    let date = [];

    while (startDate < endDate) {
        const endDateOfCycle = new Date(
            startDate.getTime() + thirtyDaysInMilliseconds
        );
        const endDateFormatted =
            endDateOfCycle < endDate ? endDateOfCycle : endDate;

        const timelineString = `${startDate.getDate()} ${startDate.toLocaleString(
            "default",
            { month: "short" }
        )}, ${startDate.getFullYear()} - ${endDateFormatted.getDate()} ${endDateFormatted.toLocaleString(
            "default",
            { month: "short" }
        )}, ${endDateFormatted.getFullYear()}`;

        timeline.push(timelineString);
        date.push(
            `${startDate.getFullYear()}-${
                startDate.getMonth() + 1
            }-${startDate.getDate()},${endDateFormatted.getFullYear()}-${
                endDateFormatted.getMonth() + 1
            }-${endDateFormatted.getDate()}`
        );

        startDate.setTime(endDateOfCycle.getTime() + millisecondsInDay);
    }

    const currentTimeline = getCurrentTimeline(timeline, date);

    return {
        status: true,
        message: "Timeline data retrieved successfully",
        timeline: timeline,
        currentTimeline: currentTimeline,
        date: date,
    };
};

export default generateTimeline;
