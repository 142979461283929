import axios from "axios";
import { getUserID, getParams } from ".";
import { getBaseURL } from "../../helpers/getBaseURL";
import * as ReducerActions from "../reducers/";
import moment from "moment";
import { toast } from "react-toastify";
import { CountryData } from "../../components/myProfile/countryData";

export const GetAllVideoVisits = () => async (dispatch, getState) => {
  try {
    const body = {
      startDateStr: "",
      endDateStr: "",
      userId: getUserID(getState),
      currentPage: 1,
      pageLimit: 20,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      tabname: "myschedule",
      subtabname: "myschedule",
      selectedClinicId: "0",
    };

    const res = await axios.post(
      `${getBaseURL()}/getUpcommingOVVVForPatientPortal${getParams(
        getState
      )}&userId=${getUserID(getState)}`,
      body
    );

    if (res.data.data !== undefined) {
      dispatch(ReducerActions.setVisits(res.data.data));
    }

    if (res.data.phoneCall !== undefined) {
      dispatch(ReducerActions.setPhoneCalls(res.data.phoneCall));
    }
  } catch (error) {
    console.log(error);
  }
};

export const RequestVideoConsultation =
  (symptomMsg, PayerType, SelectedDate, isSkip) =>
  async (dispatch, getState) => {
    console.log(getState().SurveyAndMaterial);
    try {
      const UserData = getState().User.UserData;
      const body = {
        firstName: UserData.firstName,
        lastName: UserData.lastName,
        email: UserData.email,
        phoneNo: UserData.phoneNo,
        password: "",
        isAcceptMessenger: 1,
        newUser: false,
        adminEmail: "",
        CountryId: UserData.CountryId,
        countryPhoneCode: CountryData.filter(
          (x) => x.CountryId == UserData.CountryId
        )[0].ISO2,
        expectDateTime: JSON.stringify([
          {
            st_completeDateTimeWithZone: moment(SelectedDate).format(
              "YYYY-MM-DD HH:mm:ss ZZ"
            ),
            en_completeDateTimeWithZone: moment(SelectedDate)
              .add("30", "minutes")
              .format("YYYY-MM-DD HH:mm:ss ZZ"),
            day: moment(SelectedDate).format("MM/DD/YYYY"),
            time2Start: moment(SelectedDate).format("hh:mm"),
            timeAMPMStart: moment(SelectedDate).format("A"),
            time2End: moment(SelectedDate).add("30", "minutes").format("hh:mm"),
            timeAMPMEnd: moment(SelectedDate).add("30", "minutes").format("A"),
            intial: "1",
            slots: moment(SelectedDate).format("hh:mm A"),
            language: "English",
          },
        ]).replace(/"/g, '"'),
        symptomMsg: symptomMsg || "",
        providerNote: "",
        filelist: "",
        clinicGroupItemId: UserData.ClinicGroupItem.clinicGroupItemId,
        address: UserData.address,
        state: UserData.stateCode,
        zipcode: UserData.zipcode,
        authCode: 415842,
        selectedEmployerId: "",
        payerType: PayerType || "",
        fromPatientPortal: true,
        sypLogTypeId: isSkip ? null : "266",
        sypLogSummaryId: getState().SurveyAndMaterial.sypLogSummaryId,
      };

      const res = await axios.post(
        `${getBaseURL()}/createNewVideoConsultationRequest`,
        body
      );
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong");
    }
  };

export const getAvailableTimeSlots = (id) => async (dispatch, getState) => {
  try {
    let newId = id;
    if (!id) {
      newId = getState().User.Providers[0].adminDocterId;
    }

    const res = await axios.post(
      `${getBaseURL()}/getFreeSlotsByDoctorIdClinicId`,
      {
        doctorId: "",
        clinicGroupItemId:
          getState().User.UserData.ClinicGroupItem.clinicGroupItemId,
        startDate: moment(new Date()).format("YYYY-MM-DD hh:mm:ss ZZ"),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      }
    );

    dispatch(ReducerActions.setSlots(res.data));
  } catch (error) {
    console.log(error);
    dispatch(ReducerActions.setSlots([]));
  }
};
export const cancelAppointment = (bookingId) => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${getBaseURL()}/cancelAppointment/${bookingId}${getParams(getState)}`
    );
    return res;
  } catch (error) {
    console.log(error);
  }
};
export const moveToAdminDoctorClinic =
  (doctorId, patientId) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${getBaseURL()}/addPatientToProviderClinic/${getParams(getState)}`,
        {
          adminDoctorId: doctorId,
          patientId: patientId,
        }
      );
      return res;
    } catch (error) {
      console.log(error);
    }
  };
