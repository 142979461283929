import React, { useState } from "react";
import { DARK_BLUE, LIGHT_GREEN } from "../../constants";
import { Button } from "@mui/material";
import moment from "moment";
import playBlue from "../../assests/icons/playBlue.svg";
import audio from "../../assests/icons/mp3.svg";
import text from "../../assests/icons/txt.svg";
import ppt from "../../assests/icons/ppt.svg";
import pdfIconWhite from "../../assests/images/pdfWhite.png";
import doc from "../../assests/icons/doc.svg";
import DialogWrapper from "../../helpers/DialogWrapper";
const DashboardEduMaterialCard = ({
  SelectedModule,
  SelectedModuleData,
  onClickAnyFile,
}) => {
  const [LinkForView, setLinkForView] = useState("");
  const [LinkName, setLinkName] = useState("");
  const [ClickedFileTypeId, setClickedFileTypeId] = useState("");
  const [DialogOpen, setDialogOpen] = useState("");

  // if (SelectedModuleData.length === 0) return (
  //     <div className="col-lg-7 col-md-12 mb-2" >
  //         <h3 style={{ color: "grey", marginTop: "200px", textAlign: "center" }}>No Material Found</h3>
  //     </div>
  // )
  return (
    <div className="col-lg-7 col-md-12 mb-2">
      <div className="card" style={{ border: 0 }}>
        <div
          className="d-flex align-items-center justify-content-between py-3 px-3"
          style={{
            fontSize: "14px",
            fontWeight: "500",
            borderBottom: "1px solid #e4ebf3",
          }}
        >
          <span
            style={{ color: DARK_BLUE, fontSize: "16px", fontWeight: "500" }}
          >
            Education Material
          </span>
          <div className="d-flex align-items-center">
            <span
              className="mx-1"
              style={{ color: DARK_BLUE, fontSize: "16px", fontWeight: 500 }}
            >
              Status:
            </span>
            <div>
              {SelectedModule?.programModuleStatus &&
              SelectedModule.programModuleStatus[0] != 0 ? (
                <div className="d-flex align-items-center">
                  <div
                    className="mx-2"
                    style={{
                      backgroundColor: "#009704",
                      height: "5px",
                      width: "5px",
                      borderRadius: "100%",
                    }}
                  ></div>
                  <span>Complete</span>
                </div>
              ) : (
                <div className="d-flex align-items-center">
                  <div
                    className="mx-2"
                    style={{
                      backgroundColor: "#eb0400",
                      height: "5px",
                      width: "5px",
                      borderRadius: "100%",
                    }}
                  ></div>
                  <span>Incomplete</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="row py-2 px-3">
          <div
            className="col-4"
            style={{ color: DARK_BLUE, fontWeight: "550", fontSize: "14px" }}
          >
            Task Name:
          </div>
          <div className="col-8" style={{ color: "#333333", fontSize: "14px" }}>
            {SelectedModule.moduleTitle}
          </div>
          <div
            className="col-4"
            style={{ color: DARK_BLUE, fontWeight: "550", fontSize: "14px" }}
          >
            Start Date:
          </div>
          <div className="col-8" style={{ color: "#333333", fontSize: "14px" }}>
            {moment(SelectedModule.moduleStartDate * 1000).format(
              "MMMM DD, YYYY"
            )}
          </div>
          {SelectedModule.lengthUnit != "One Time" ? (
            <>
              <div
                className="col-4"
                style={{
                  color: DARK_BLUE,
                  fontWeight: "550",
                  fontSize: "14px",
                }}
              >
                End Date:
              </div>
              <div
                className="col-8"
                style={{ color: "#333333", fontSize: "14px" }}
              >
                {moment(SelectedModule.moduleEndDate * 1000).format(
                  "MMMM DD, YYYY"
                )}
              </div>
            </>
          ) : null}
          <div
            className="col-4"
            style={{ color: DARK_BLUE, fontWeight: "550", fontSize: "14px" }}
          >
            Prescribed Date:
          </div>
          <div className="col-8" style={{ color: "#333333", fontSize: "14px" }}>
            {moment(SelectedModule.moduleStartDate * 1000).format(
              "MMMM DD, YYYY"
            )}
          </div>
          <div
            className="col-4"
            style={{ color: DARK_BLUE, fontWeight: "550", fontSize: "14px" }}
          >
            Repeat For:
          </div>
          <div className="col-8" style={{ color: "#333333", fontSize: "14px" }}>
            {SelectedModule.lengthUnit}
          </div>
          {SelectedModule.lengthUnit !== "One Time" && (
            <>
              <div
                className="col-4"
                style={{
                  color: DARK_BLUE,
                  fontWeight: "550",
                  fontSize: "14px",
                }}
              >
                Days Per Week:
              </div>
              <div
                className="col-8"
                style={{
                  color: "#333333",
                  fontSize: "14px",
                  display: "flex",
                  justifyContent: "start",
                }}
              >
                {SelectedModuleData[0]?.timePerWkTypeId?.map((value, index) => (
                  <div key={index}>
                    {value}
                    {index !==
                      SelectedModuleData?.timePerWkTypeId?.length - 1 && ","}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
        <div className="row px-3 mt-3 mb-5">
          {SelectedModuleData.length === 0 ? (
            <div className="col-lg-12 col-md-12 col-sm-12 mt-3 px-2">
              <h3 style={{ color: "grey", textAlign: "center" }}>
                No Material Found
              </h3>
            </div>
          ) : null}
          {SelectedModuleData.map((value, index) => {
            return (
              <div
                key={index}
                className="col-lg-4 col-md-6 col-sm-12 mt-3 px-2"
              >
                <div
                  className="card"
                  style={{ borderColor: "rgba(0, 184, 187, 0.26)" }}
                >
                  <div
                    style={{
                      height: "100px",
                      width: "100%",
                      backgroundImage:
                        value.fileTypeId == "1"
                          ? "url(https://d3i9v5bkcvmkzb.cloudfront.net/" +
                            value.filename +
                            ")"
                          : "url(https://dfi4wfwfroz6l.cloudfront.net/" +
                            value.image +
                            ")",
                      backgroundSize: "cover",
                      backgroundColor: LIGHT_GREEN,
                      overflow: "hidden",
                    }}
                    className="d-flex justify-content-center align-items-center"
                  >
                    {value.fileTypeId === "1" ? (
                      <img
                        alt="icon"
                        style={{ width: "100%" }}
                        src={
                          "https://d3i9v5bkcvmkzb.cloudfront.net/" +
                          value.filename
                        }
                      />
                    ) : null}
                    {value.fileTypeId === "2" ? (
                      <img
                        alt="icon"
                        style={{ width: "45px", cursor: "pointer" }}
                        src={playBlue}
                        onClick={() => {}}
                      />
                    ) : null}
                    {value.fileTypeId === "3" ? (
                      <img alt="icon" style={{ width: "45px" }} src={audio} />
                    ) : null}
                    {value.fileTypeId === "4" ? (
                      <img alt="icon" style={{ width: "45px" }} src={text} />
                    ) : null}
                    {value.fileTypeId === "5" ? (
                      <img
                        alt="icon"
                        style={{ width: "45px" }}
                        src={pdfIconWhite}
                      />
                    ) : null}
                    {value.fileTypeId === "6" ? (
                      <img alt="icon" style={{ width: "45px" }} src={ppt} />
                    ) : null}
                    {value.fileTypeId === "7" ? (
                      <img alt="icon" style={{ width: "45px" }} src={doc} />
                    ) : null}
                  </div>
                  <div>
                    <p
                      className="my-2 pb-1 px-2"
                      style={{
                        color: DARK_BLUE,
                        fontSize: "16px",
                        borderBottom: "1px solid #e4ebf3",
                        textOverflow: "ellipsis",
                        overflow: "hidden",
                        whiteSpace: "nowrap",
                      }}
                    >
                      {value.videoName}
                    </p>
                    <div className="mx-2 my-2">
                      <div className="d-flex align-items-center justify-content-end">
                        {value.fileTypeId === "2" ? (
                          <Button
                            variant="contained"
                            style={{
                              background: DARK_BLUE,
                              color: "white",
                              marginLeft: "10px",
                              textTransform: "capitalize",
                            }}
                            size="small"
                            onClick={() => {
                              setLinkForView(
                                "https://d3i9v5bkcvmkzb.cloudfront.net/" +
                                  value.filename
                              );
                              setClickedFileTypeId(value.fileTypeId);
                              setLinkName(value.videoName);
                              setDialogOpen(true);
                              onClickAnyFile(value);
                            }}
                          >
                            Play
                          </Button>
                        ) : (
                          <Button
                            onClick={() => {
                              if (
                                value.fileTypeId === "3" ||
                                value.fileTypeId === "4" ||
                                value.fileTypeId === "6" ||
                                value.fileTypeId === "7"
                              ) {
                                window.open(
                                  "https://d3i9v5bkcvmkzb.cloudfront.net/" +
                                    value.filename,
                                  "_blank"
                                );
                                onClickAnyFile(value);
                                return;
                              }
                              setLinkForView(
                                "https://d3i9v5bkcvmkzb.cloudfront.net/" +
                                  value.filename
                              );
                              setClickedFileTypeId(value.fileTypeId);
                              setLinkName(value.videoName);
                              setDialogOpen(true);
                              onClickAnyFile(value);
                            }}
                            variant="contained"
                            style={{
                              height: "25px",
                              background: DARK_BLUE,
                              color: "white",
                              marginLeft: "10px",
                              textTransform: "capitalize",
                            }}
                            size="small"
                          >
                            View
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <DialogWrapper
        open={DialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="lg"
        title={LinkName}
        NoFooter={true}
        content={
          <div
            style={{
              width: "100%",
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              background: ClickedFileTypeId == "1" ? "#eee" : "",
            }}
          >
            {ClickedFileTypeId == "1" ? (
              <img
                alt="materialImage"
                style={{ height: "100%" }}
                src={LinkForView}
              />
            ) : null}
            {ClickedFileTypeId == "2" ? (
              <video
                alt="materialVideo"
                style={{ width: "100%" }}
                src={LinkForView}
                controls
                autoPlay
              />
            ) : null}
            {ClickedFileTypeId == "5" ? (
              <iframe
                title={LinkName}
                alt="materialPDF"
                style={{ width: "100%", height: "100%" }}
                src={LinkForView}
              />
            ) : null}
          </div>
        }
      />
    </div>
  );
};

export default DashboardEduMaterialCard;
