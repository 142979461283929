import { createSlice } from "@reduxjs/toolkit";

export const MessagesSlice = createSlice({
    name: "Messages",
    initialState: {
        Messages: [],
        Notes: [],
        Threads: [],
        Chats: {},
    },
    reducers: {
        setMessages: (state, action) => {
            state.Messages = action.payload;

        },
        setNotes: (state, action) => {
            state.Notes = action.payload;

        },
        setThreads: (state, action) => {
            state.Threads = action.payload;

        },
        setChats: (state, action) => {
            state.Chats = action.payload;

        },

    },
});

export const { setMessages, setNotes, setThreads, setChats } = MessagesSlice.actions;


export const MessagesReducer = MessagesSlice.reducer;