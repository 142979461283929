import { createSlice } from "@reduxjs/toolkit";

export const ManageClientsSlice = createSlice({
    name: "ManageClients",
    initialState: {
        manageClientsList: [],
        companyList: [],
        siteList: [],
        // employeeList: []
    },

    reducers: {
        setManageClientsList: (state, action) => {
            state.manageClientsList = action.payload;
        },
        
        setCompanyList: (state, action) => {
            state.companyList = action.payload;
        },
        
        setSiteList: (state, action) => {
            state.siteList = action.payload;
        },
        
        // setEmployeeList: (state, action) => {
        //     state.employeeList = action.payload;
        // },
    },
});

export const { setManageClientsList, setCompanyList, setSiteList } = ManageClientsSlice.actions;


export const ManageClientsReducer = ManageClientsSlice.reducer;