import { createSlice } from "@reduxjs/toolkit";

export const RTMService = createSlice({
  name: "RTM",
  initialState: {
    RTMData: [],
    RTMPrograms: [],
    RTMProgramModules: [],
    RTMProgramDetails: {},
    RTMProgramModulesNoOfMonth: [],
    RTMDataForCalender: [],
    RTMMonths: [],
    SaveAddModuleData: [],
    LibraryDropdownEmaterial: [],
    LibraryDropdownHEP: [],
    LibraryDropdownSurvay: [],
    RTMModuleDetail: [],
    RTMPatientModuleDetail: [],
    TaskCategoryLkp: [],
    TaskDataTypeLkp: [],
    TaskDataTypeLkpid: [],
    RTMProgramCPTCodeBill: [],
    Seconds: [],
    AllTelehealthModules: [],
    BluejayLibraryCategories: [],
    ICDLibrary: [],
    FavoriteLibrary: [],
    RTMLetter: [],
    SurgeryLibrary: [],
  },
  reducers: {
    setSeconds: (state, action) => {
      state.Seconds = action.payload;
    },
    setRTMData: (state, action) => {
      state.RTMData = action.payload;
    },
    setRTMProgramsData: (state, action) => {
      state.RTMPrograms = action.payload;
    },
    setRTMProgramModulesData: (state, action) => {
      state.RTMProgramModules = action.payload;
    },
    setRTMProgramDetailData: (state, action) => {
      state.RTMProgramDetails = action.payload;
    },
    setRTMProgramModulesNoOfMonth: (state, action) => {
      state.RTMProgramModulesNoOfMonth = action.payload;
    },
    setRTMPDataForCalender: (state, action) => {
      state.RTMDataForCalender = action.payload;
    },
    setRTMMonths: (state, action) => {
      state.RTMMonths = action.payload;
    },
    setSaveAddModuleData: (state, action) => {
      state.SaveAddModuleData = action.payload;
    },
    setAdminDocterClinicLibraryEmaterial: (state, action) => {
      state.LibraryDropdownEmaterial = action.payload;
    },
    setAdminDocterClinicLibraryHEP: (state, action) => {
      state.LibraryDropdownHEP = action.payload;
    },
    setSurveyTypeListByClinic: (state, action) => {
      state.LibraryDropdownSurvay = action.payload;
    },
    setModuleDetailByModuleIdModuleTypeId: (state, action) => {
      state.RTMModuleDetail = action.payload;
    },
    setPatientModuleByModuleIdModuleTypeId: (state, action) => {
      state.RTMPatientModuleDetail = action.payload;
    },
    setTaskCategoryLkp: (state, action) => {
      state.TaskCategoryLkp = action.payload;
    },
    setTaskDataTypeLkp: (state, action) => {
      state.TaskDataTypeLkp = action.payload;
    },
    setTaskDataTypeLkpid: (state, action) => {
      state.TaskDataTypeLkpid = action.payload;
    },
    setRTMProgramCPTCodeBill: (state, action) => {
      state.RTMProgramCPTCodeBill = action.payload;
    },
    setAllTelehealth: (state, action) => {
      state.AllTelehealthModules = action.payload;
    },
    setBluejayLibraryCategories: (state, action) => {
      state.BluejayLibraryCategories = action.payload;
    },
    setICDCodes: (state, action) => {
      state.ICDLibrary = action.payload;
    },
    setFavoriteLibrary: (state, action) => {
      state.FavoriteLibrary = action.payload;
    },
    setSurgeryLibrary: (state, action) => {
      state.SurgeryLibrary = action.payload;
    },
    setRTMLetter: (state, action) => {
      state.RTMLetter = action.payload;
    },
  },
});

export const {
  setRTMMonths,
  setRTMPDataForCalender,
  setRTMData,
  setRTMProgramsData,
  setRTMProgramModulesData,
  setRTMProgramDetailData,
  setRTMProgramModulesNoOfMonth,
  setSaveAddModuleData,
  setAdminDocterClinicLibraryEmaterial,
  setAdminDocterClinicLibraryHEP,
  setSurveyTypeListByClinic,
  setModuleDetailByModuleIdModuleTypeId,
  setPatientModuleByModuleIdModuleTypeId,
  setTaskCategoryLkp,
  setTaskDataTypeLkp,
  setTaskDataTypeLkpid,
  setRTMProgramCPTCodeBill,
  setSeconds,
  setAllTelehealth,
  setBluejayLibraryCategories,
  setICDCodes,
  setFavoriteLibrary,
  setRTMLetter,
  setSurgeryLibrary
} = RTMService.actions;

export const RTMReducer = RTMService.reducer;
