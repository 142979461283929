import moment from "moment";
import React, { useEffect, useState } from "react";
import BackCarrotBlue from "../../assests/icons/backCarrotBlue.svg";
import ForwardCarrotBlue from "../../assests/icons/forwardCarrotBlue.svg";
import { DARK_BLUE,LIGHT_BLUE } from "../../constants";

const datesArr = [
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "",
];

const weeks = [
    "Sun",
    "Mon",
    "Tue",
    "Wed",
    "Thu",
    "Fri",
    "Sat",
];

const Calender = (props) => {


    const [InitDateForCalendar, setInitDateForCalendar] = useState(moment(new Date()).startOf("month").toISOString());
    // const [Multiplyer, setMultiplyer] = useState(0);
    const [FirstIndex, setFirstIndex] = useState(0);
    const [SelectedDate, setSelectedDate] = useState("");


    useEffect(() => {
        const newThisDate = sessionStorage.getItem("@month");
        if (props.InitDateForCalendar) {
            const temp_date = moment.unix(props.InitDateForCalendar).startOf("month").toISOString();
            setInitDateForCalendar(temp_date);
        } else if (newThisDate) {
            setInitDateForCalendar(newThisDate);
        }
    }, [props.InitDateForCalendar]);

    useEffect(() => {
        const dateNew = sessionStorage.getItem("@CurrentDate");
        if (dateNew) {
            setSelectedDate(dateNew);
        } else {
            setSelectedDate(new Date().toISOString());
            sessionStorage.setItem("@CurrentDate", SelectedDate);
        }

        return () => {
            sessionStorage.removeItem("@CurrentDate");
        };

    }, [props.id]);

    useEffect(() => {
        if (SelectedDate) {
            sessionStorage.setItem("@CurrentDate", SelectedDate);
            props.ChangeDate(moment(SelectedDate).format("YYYY-MM-DD"));
        }

    }, [SelectedDate]);

    useEffect(() => {
        const thisDay = moment(InitDateForCalendar);
        const startOfThisMonth = thisDay.startOf("month");
        const dayOn1st = startOfThisMonth.format("ddd");
        const indexFor1stDay = getIndexOfDateInFirstWeek(dayOn1st);
        setFirstIndex(indexFor1stDay);
        // setFirstIndex(getIndexOfDateInFirstWeek(moment(new Date()).subtract(parseInt(moment(new Date()).format("DD")) - 1, "day").format("ddd")));
    }, [InitDateForCalendar]);
    function getIndexOfDateInFirstWeek(day) {
        if (day === "Sun") return 0;
        if (day === "Mon") return 1;
        if (day === "Tue") return 2;
        if (day === "Wed") return 3;
        if (day === "Thu") return 4;
        if (day === "Fri") return 5;
        if (day === "Sat") return 6;
    }

    useEffect(() => {
        // Set the initial date to the start of the current month
        const currentMonthStart = moment().startOf("month").toISOString();
        setInitDateForCalendar(currentMonthStart);
        sessionStorage.setItem("@month", currentMonthStart);
    }, []);
    return (
        <div className="mt-3" >
            <div className="d-flex align-items-center justify-content-center " >
                <img
                    alt="icon"
                    className="mx-2 p-2"
                    style={{ cursor: "pointer" }}
                    src={BackCarrotBlue}
                    onClick={() => {
                        const thisDate = moment(InitDateForCalendar).subtract(1, "month").toISOString();
                        setInitDateForCalendar(thisDate);
                        sessionStorage.setItem("@month", thisDate);
                    }}
                />
                <p
                    style={{ fontWeight: "500", fontSize: "20px", color: DARK_BLUE }} className="my-0 mx-2"
                >
                    {moment(InitDateForCalendar).add(1, "day").format("MMMM YYYY")}
                </p>
                <img
                    alt="icon"
                    className="mx-2 p-2 "
                    style={{ cursor: "pointer" }}
                    src={ForwardCarrotBlue}
                    onClick={() => {
                        const thisDate = moment(InitDateForCalendar).add(1, "month").toISOString();
                        setInitDateForCalendar(thisDate);
                        sessionStorage.setItem("@month", thisDate);
                    }}
                />
            </div>
            <div style={{ width: "100%", height: "40px", display: "flex", flexDirection: "row" }}>
                {
                    weeks.map((value, index) => {
                        return (
                            <div key={index} style={{ flex: 1, display: "flex", alignItems: "flex-end" }}>
                                <span style={{ color: "grey", fontSize: "13px" }}>
                                    {
                                        value
                                    }
                                </span>
                            </div>
                        );
                    })
                }

            </div>
            <div
                className="text-center "
                style={{
                    height: "300px",
                    width: "100%",
                    border: ".3px solid grey",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap"
                }} >
                {
                    datesArr.map((value, index) => {
                        const thisModule = props.ModuleWithDates[moment(InitDateForCalendar).add(index - FirstIndex, "day").format("YYYY-MM-DD")];
                        let thisStatus = thisModule?.programModuleStatus;
                        
                        if (thisModule?.moduleTypeLkpId == 3 || thisModule?.moduleTypeLkpId == 2) {
                            if (thisStatus) thisStatus = 1;
                        }
                        // console.log(thisStatus)
                        return (
                            <div
                                key={index}
                                style={{
                                    width: "14.2857142857%",
                                    height: "",
                                    border: ".3px solid grey",
                                    display: "flex",
                                    // padding: "5px",
                                    cursor: "pointer",
                                    background: moment(InitDateForCalendar).add(index - FirstIndex, "day").format("YYYY-MM-DD") == moment(SelectedDate).format("YYYY-MM-DD") ? "#e4ebf3" : ""
                                }}
                                onClick={() => {
                                    setSelectedDate(moment(InitDateForCalendar).add(index - FirstIndex, "day").toISOString());
                                    // alert(JSON.stringify(thisModule))
                                }}
                            >
                                <span style={{
                                    color: moment(InitDateForCalendar).add(1, "day").format("MMMM") === moment(InitDateForCalendar).add(index - FirstIndex, "day").format("MMMM") ? "grey" : "#ccc",
                                    fontSize: "12px",
                                    width: "100%"
                                }} >
                                    <div  style={{ width: "100%", textAlign: "left", padding: "5px" }}>
                                        {
                                            moment(InitDateForCalendar).add(index - FirstIndex, "day").format("DD")
                                        }
                                     
                                    </div>

                                    {
                                        props.Dates.includes(moment(InitDateForCalendar).add(index - FirstIndex, "day").format("YYYY-MM-DD")) ? (
                                            <div style={{
                                                height: "10px",
                                                width: "30px",
                                                background: LIGHT_BLUE,  //thisStatus && thisStatus > 0 ? "green" : "red",
                                                borderTopRightRadius: "10px",
                                                borderBottomRightRadius: "10px",

                                            }} />
                                        ) : null
                                    }
                                  
                                </span>
                            </div>
                        );
                    })
                }
            </div>
        </div>
    );
};

export default Calender;