exports.getCurrentDate = function() {
    const today = new Date();
    const year = today.getFullYear();
    const month = (today.getMonth() + 1).toString().padStart(2, '0'); 
    const day = today.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}
exports.getTomorrowDate = function() {
    const tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    const year = tomorrow.getFullYear();
    const month = (tomorrow.getMonth() + 1).toString().padStart(2, '0');
    const day = tomorrow.getDate().toString().padStart(2, '0');
    return `${year}-${month}-${day}`;
}; 
exports.getNextDay = function(inputDate) {
    const currentDate = new Date(inputDate);
    currentDate.setDate(currentDate.getDate() + 1);
    
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    
    return `${year}-${month}-${day}`;
}

exports.add30Days = function (startDateStr) {
    // Convert the input string to a Date object
    const startDate = new Date(startDateStr);

    // Add 30 days to the start date
    const resultDate = new Date(startDate);
    resultDate.setDate(resultDate.getDate() + 30);

    // Return the result date as a string
    return resultDate;
}