import axios from "axios";
import { getUserID, getParams } from ".";
import { getBaseURL } from "../../helpers/getBaseURL";
import * as ReducerActions from "../reducers/";

export const GetAllHealthData = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${getBaseURL()}/UserHealthDataListByUserId/${getUserID(
        getState
      )}/${getParams(getState)}`
    );

    if (res.data.error) {
      return res.data.msg;
    }

    dispatch(ReducerActions.setHealthData(res.data || null));
  } catch (error) {
    console.log(error);
  }
};

export const UpdateHealthData =
  (userHealthDataId, healthDataValue, healthFactsMeasurementRelId) =>
  async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${getBaseURL()}/UserHealthData/${getUserID(getState)}/${getUserID(
          getState
        )}/${getUserID(getState)}/${getUserID(getState)}/${getParams(
          getState
        )}`,
        [
          {
            userHealthDataId: userHealthDataId,
            healthDataValue: healthDataValue,
            logDate: new Date().toISOString(),
            healthFactsMeasurementRelId,
            userId: getUserID(getState),
          },
        ]
      );
    } catch (error) {
      console.log(error);
    }
  };
export const submitKemtaiHealthData =
  (feedBackData, kemtaiData) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${getBaseURL()}/SubmitWorkoutLogData/${getParams(getState)}`,
        {
          userId: getUserID(getState),
          workoutFeedback: feedBackData,
          workoutResponse: kemtaiData,
        }
      );
      dispatch(ReducerActions.setKemtaiHealthData(res.data || {}));
    } catch (error) {
      console.log(error);
    }
  };

export const assignHEPKemtai = (exercises) => async (dispatch, getState) => {
  try {
    const res = await axios.post(
      `${getBaseURL()}/AddWorkoutTreatment/${getParams(getState)}`,
      {
        userId: getUserID(getState),
        "exercise" : exercises
      }
    );
    return res
  } catch (error) {
    console.log(error);
  }
};
