import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { GOLD, LIGHT_GREEN, SIDE_BAR_HOVER } from "../../constants";
import MenuIcon from "../../assests/icons/menu.svg";
import DashboardGold from "../../assests/icons/forSideBar/DashboardGold.svg";
import DashboardWhite from "../../assests/icons/forSideBar/DashboardWhite.svg";
import MessageGold from "../../assests/icons/forSideBar/MessageGold.svg";
import MessageWhite from "../../assests/icons/forSideBar/MessageWhite.svg";
import GoalsGold from "../../assests/icons/forSideBar/GoalsGold.svg";
import GoalsWhite from "../../assests/icons/forSideBar/GoalsWhite.svg";
import DocsGold from "../../assests/icons/forSideBar/DocsGold.svg";
import DocsWhite from "../../assests/icons/forSideBar/DocsWhite.svg";
import SurveyGold from "../../assests/icons/forSideBar/SurveyGold.svg";
import SurveyWhite from "../../assests/icons/forSideBar/SurveyWhite.svg";
import HepGold from "../../assests/icons/forSideBar/HepGold.svg";
import FitbitWhite from "../../assests/icons/forSideBar/FitbitWhite.svg";
import FitbitGold from "../../assests/icons/forSideBar/FitbitGold.svg";
import HepWhite from "../../assests/icons/forSideBar/HepWhite.svg";
import AccountGold from "../../assests/icons/forSideBar/AccountGold.svg";
import AccountWhite from "../../assests/icons/forSideBar/AccountWhite.svg";
import HelpGold from "../../assests/icons/forSideBar/HelpGold.svg";
import HelpWhite from "../../assests/icons/forSideBar/HelpWhite.svg";
import Logout from "../../assests/icons/forSideBar/Logout.svg";
import CarrotGold from "../../assests/icons/forSideBar/CarrotGold.svg";
import CarrotWhite from "../../assests/icons/forSideBar/CarrotWhite.svg";
import CalenderWhite from "../../assests/icons/forSideBar/calendar.svg";
import VCRWhite from "../../assests/icons/forSideBar/VCRWhite.svg";
import VCRGOld from "../../assests/icons/forSideBar/VCRGOld.svg";
import UCWhite from "../../assests/icons/forSideBar/UCWhite.svg";
import UCGold from "../../assests/icons/forSideBar/UCGold.svg";
import HealthGold from "../../assests/icons/forSideBar/HealthGold.svg";
import HealthWhite from "../../assests/icons/forSideBar/HealthWhite.svg";
import HealthServiceGold from "../../assests/icons/forSideBar/HealthServiceGold.svg";
import HealthServiceWhite from "../../assests/icons/forSideBar/HealthServiceWhite.svg";



import { useLocation, useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { LogoutSuccess } from "../../store/reducers";
import { Menu, MenuItem } from "@mui/material";

const Sidebar = props => {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const [CurrentRoute, setCurrentRoute] = useState("dashboard");
    const [AccountOpen, setAccountOpen] = useState(false);
    const [ScheduleOpen, setScheduleOpen] = useState(false);
    const [HealthServicesOpen, setHealthServicesOpen] = useState(false);
    const [MyProgramsOpen, setMyProgramsOpen] = useState(false);


    const [anchorEl, setAnchorEl] = React.useState(null);
    const [MenuType, setMenuType] = React.useState("");
    const open = Boolean(anchorEl);



    const UserData = useSelector(state => state.User.UserData);
    const AllJoinedPrograms = useSelector((state) => state.Programs.AllJoinedProgramsFromThisUser);
    const IsSideBarOpen = useSelector(state => state.UI.isSideBarOpen);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        if (location.pathname) {
            setCurrentRoute(location.pathname.split("/")[1]);
            if (location.pathname.split("/")[1] === "myProfile" || location.pathname.split("/")[1] === "providers") {
                setAccountOpen(true);
            } else {
                setAccountOpen(false);
            }

            if (location.pathname.split("/")[1] === "book-consultation" || location.pathname.split("/")[1] === "upcomingVisit") {
                setScheduleOpen(true);
            } else {
                setScheduleOpen(false);
            }
            if (location.pathname.split("/")[1].includes("programs") || location.pathname.split("/")[1] === "webinars" || location.pathname.split("/")[1] === "program") {
                setHealthServicesOpen(true);
            } else {
                setHealthServicesOpen(false);
            }
            if (location.pathname.split("/")[1].includes("ongoing-program")) {
                setMyProgramsOpen(true);
            } else {
                setMyProgramsOpen(false);
            }
        }
    }, [location]);




    return (
        <div style={{ height: "100%", width: "100%", background: LIGHT_GREEN, overflowY: "scroll" }}>
            {/* Menu Start  */}
            <div
                onClick={() => navigate("/dashboard")}
                style={{ width: "250px", height: "50px", display: "flex", cursor: "pointer", borderLeft: CurrentRoute === "dashboard" ? `3px solid ${GOLD}` : "", background: CurrentRoute === "dashboard" ? SIDE_BAR_HOVER : "" }}
                className="CanHover"
            >
                <div
                    className="d-flex justify-content-center align-items-center "
                    style={{ width: "60px", height: "100%", }}
                >
                    <img
                        alt="icon"

                        src={CurrentRoute === "dashboard" ? DashboardWhite : DashboardWhite}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <div className="d-flex  align-items-center" style={{ flex: 1, }}>
                    <span style={{ color: CurrentRoute === "dashboard" ? GOLD : "white", fontSize: "14px" }}>
                        Dashboard
                    </span>
                </div>
            </div>
            {/* Menu End  */}
            {/* Menu Start  */}
            <div
                onClick={() => navigate("/messages")}
                style={{ width: "250px", height: "50px", display: "flex", cursor: "pointer", borderLeft: CurrentRoute === "messages" ? `3px solid ${GOLD}` : "", background: CurrentRoute === "messages" ? SIDE_BAR_HOVER : "" }}
                className="CanHover"
            >
                <div
                    className="d-flex justify-content-center align-items-center "
                    style={{ width: "60px", height: "100%", }}
                >
                    <img
                        alt="icon"

                        src={CurrentRoute === "messages" ? MessageWhite : MessageWhite}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <div className="d-flex  align-items-center" style={{ flex: 1, }}>
                    <span style={{ color: CurrentRoute === "messages" ? GOLD : "white", fontSize: "14px" }}>
                        Messages
                    </span>
                </div>
            </div>

            {/* Menu End  */}
            {/* Menu Start  */}
            {/* <div
                onClick={() => navigate("/goalsAndOutcomes")}
                style={{ width: "250px", height: "50px", display: "flex", cursor: "pointer", borderLeft: CurrentRoute === "goalsAndOutcomes" ? `3px solid ${GOLD}` : "", background: CurrentRoute === "goalsAndOutcomes" ? SIDE_BAR_HOVER : "" }}
                className="CanHover"
            >
                <div
                    className="d-flex justify-content-center align-items-center "
                    style={{ width: "60px", height: "100%", }}
                >
                    <img
                        alt="img"

                        src={CurrentRoute === "goalsAndOutcomes" ? GoalsGold : GoalsWhite}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <div className="d-flex  align-items-center" style={{ flex: 1, }}>
                    <span style={{ color: CurrentRoute === "goalsAndOutcomes" ? GOLD : "white", fontSize: "14px" }}>
                        Goals & Health Data
                    </span>
                </div>
            </div> */}
            {/* Menu End  */}

            <div

                onClick={(e) => {
                    // if (!CurrentRoute.includes("programs") && !HealthServicesOpen) {
                    //     navigate("/programs");
                    // }
                    if (!IsSideBarOpen) {
                        setMenuType("HealthServices");
                        handleClick(e);
                        return;
                    }
                    setMyProgramsOpen(!MyProgramsOpen);
                }}
                style={{ width: "250px", height: "50px", display: AllJoinedPrograms.length == 0 ? "none" : "flex", cursor: "pointer", borderLeft: CurrentRoute === "" ? `3px solid ${GOLD}` : "", background: CurrentRoute === "" ? SIDE_BAR_HOVER : "" }}
                className="CanHover"
            >
                <div
                    className="d-flex justify-content-center align-items-center "
                    style={{ width: "60px", height: "100%", }}
                >
                    <img
                        alt="img"

                        src={CurrentRoute === "" ? HealthWhite : HealthWhite}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <div className="d-flex  align-items-center justify-content-between" style={{ flex: 1, paddingRight: "20px" }}>
                    <span style={{ color: CurrentRoute === "" ? GOLD : "white", fontSize: "14px", }}>
                        My Programs
                    </span>
                    <img
                        alt="img" src={CarrotWhite} style={{ transform: `rotate(${MyProgramsOpen ? "180" : "360"}deg)` }} />
                </div>
            </div>
            {
                IsSideBarOpen && MyProgramsOpen ? (
                    <>
                        {
                            AllJoinedPrograms.map((value, index) => {
                                if (!value.programId) return null;
                                return (
                                    <div
                                        key={index}
                                        onClick={() => navigate("/ongoing-program/" + value.programId)}
                                        style={{ width: "250px", height: "50px", display: "flex", cursor: "pointer", borderLeft: CurrentRoute.includes("ongoing-program") && location.pathname.split("/")[location.pathname.split("/").length - 1] == value.programId ? `3px solid ${GOLD}` : "", background: CurrentRoute.includes("ongoing-program") && location.pathname.split("/")[location.pathname.split("/").length - 1] == value.programId ? SIDE_BAR_HOVER : "" }}
                                        className="CanHover"
                                    >
                                        <div
                                            className="d-flex justify-content-center align-items-center "
                                            style={{ width: "60px", height: "100%", }}
                                        >

                                        </div>
                                        <div
                                            className="d-flex justify-content-center align-items-center "
                                            style={{ height: "100%", }}
                                        >
                                        </div>
                                        <div className="d-flex  align-items-center justify-content-between" style={{ flex: 1, paddingRight: "20px" }}>
                                            <span style={{ color: CurrentRoute.includes("ongoing-program") && location.pathname.split("/")[location.pathname.split("/").length - 1] == value.programId ? GOLD : "white", fontSize: "14px", }}>
                                                {value.title}
                                            </span>
                                        </div>
                                    </div>
                                );
                            })
                        }
                        {/* <div
                            onClick={() => navigate("/completedPrograms")}
                            style={{ width: "250px", height: "50px", display: "flex", cursor: "pointer", borderLeft: CurrentRoute.includes("completedPrograms") ? `3px solid ${GOLD}` : "", background: CurrentRoute.includes("completedPrograms") ? SIDE_BAR_HOVER : "" }}
                            className="CanHover"
                        >
                            <div
                                className="d-flex justify-content-center align-items-center "
                                style={{ width: "60px", height: "100%", }}
                            >

                            </div>
                            <div
                                className="d-flex justify-content-center align-items-center "
                                style={{ height: "100%", }}
                            >
                            </div>
                            <div className="d-flex  align-items-center justify-content-between" style={{ flex: 1, paddingRight: "20px" }}>
                                <span style={{ color: CurrentRoute.includes("completedPrograms") ? GOLD : "white", fontSize: "14px", }}>
                                    Completed Programs
                                </span>
                            </div>
                        </div> */}
                    </>
                ) : null

            }




            {/* <div
                onClick={() => {
                    if (!CurrentRoute.includes("programs") && !HealthServicesOpen) {
                        navigate("/programs");
                    }
                    setHealthServicesOpen(!HealthServicesOpen);
                }}
                style={{ width: "250px", height: "50px", display: "flex", cursor: "pointer", borderLeft: CurrentRoute === "" ? `3px solid ${GOLD}` : "", background: CurrentRoute === "" ? SIDE_BAR_HOVER : "" }}
                className="CanHover"
            >
                <div
                    className="d-flex justify-content-center align-items-center "
                    style={{ width: "60px", height: "100%", }}
                >
                    <img
                        alt="img"

                        src={CurrentRoute === "" ? HealthServiceGold : HealthServiceWhite}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <div className="d-flex  align-items-center justify-content-between" style={{ flex: 1, paddingRight: "20px" }}>
                    <span style={{ color: CurrentRoute === "" ? GOLD : "white", fontSize: "14px", }}>
                        Health Services
                    </span>
                    <img
                        alt="img" src={CarrotWhite} style={{ transform: `rotate(${AccountOpen ? "180" : "360"}deg)` }} />
                </div>
            </div>
            {
                IsSideBarOpen && HealthServicesOpen ? (
                    <>
                        <div
                            onClick={() => navigate("/programs")}
                            style={{ width: "250px", height: "50px", display: "flex", cursor: "pointer", borderLeft: CurrentRoute.includes("programs") || CurrentRoute.includes("program") ? `3px solid ${GOLD}` : "", background: CurrentRoute.includes("programs") || CurrentRoute.includes("program") ? SIDE_BAR_HOVER : "" }}
                            className="CanHover"
                        >
                            <div
                                className="d-flex justify-content-center align-items-center "
                                style={{ width: "60px", height: "100%", }}
                            >

                            </div>
                            <div
                                className="d-flex justify-content-center align-items-center "
                                style={{ height: "100%", }}
                            >
                            </div>
                            <div className="d-flex  align-items-center justify-content-between" style={{ flex: 1, paddingRight: "20px" }}>
                                <span style={{ color: CurrentRoute.includes("programs") || CurrentRoute.includes("program") ? GOLD : "white", fontSize: "14px", }}>
                                    Programs
                                </span>
                            </div>
                        </div>
                        {/* <div
                            onClick={() => navigate("/webinars")}
                            style={{ width: "250px", height: "50px", display: "flex", cursor: "pointer", borderLeft: CurrentRoute === "webinars" ? `3px solid ${GOLD}` : "", background: CurrentRoute === "webinars" ? SIDE_BAR_HOVER : "" }}
                            className="CanHover"
                        >
                            <div
                                className="d-flex justify-content-center align-items-center "
                                style={{ width: "60px", height: "100%", }}
                            >

                            </div>
                            <div
                                className="d-flex justify-content-center align-items-center "
                                style={{ height: "100%", }}
                            >
                            </div>
                            <div className="d-flex  align-items-center justify-content-between" style={{ flex: 1, paddingRight: "20px" }}>
                                <span style={{ color: CurrentRoute === "webinars" ? GOLD : "white", fontSize: "14px", }}>
                                    Webinars
                                </span>
                            </div>
                        </div> */}
                    {/* </>
                ) : null
            } */}




            {/* Menu Start  */}
            <div
                onClick={() => navigate("/documents")}
                style={{ width: "250px", height: "50px", display: "flex", cursor: "pointer", borderLeft: CurrentRoute === "documents" ? `3px solid ${GOLD}` : "", background: CurrentRoute === "documents" ? SIDE_BAR_HOVER : "" }}
                className="CanHover"
            >
                <div
                    className="d-flex justify-content-center align-items-center "
                    style={{ width: "60px", height: "100%", }}
                >
                    <img
                        alt="img"

                        src={CurrentRoute === "documents" ? DocsWhite : DocsWhite}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <div className="d-flex  align-items-center" style={{ flex: 1, }}>
                    <span style={{ color: CurrentRoute === "documents" ? GOLD : "white", fontSize: "14px" }}>
                        Document
                    </span>
                </div>
            </div>
            {/* Menu End  */}
            {/* Menu Start  */}
            <div
                onClick={() => navigate("/surveyAndMaterial")}
                style={{ width: "250px", height: "50px", display: "flex", cursor: "pointer", borderLeft: CurrentRoute === "surveyAndMaterial" ? `3px solid ${GOLD}` : "", background: CurrentRoute === "surveyAndMaterial" ? SIDE_BAR_HOVER : "" }}
                className="CanHover"
            >
                <div
                    className="d-flex justify-content-center align-items-center "
                    style={{ width: "60px", height: "100%", }}
                >
                    <img
                        alt="img"

                        src={CurrentRoute === "surveyAndMaterial" ? SurveyWhite : SurveyWhite}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <div className="d-flex  align-items-center" style={{ flex: 1, }}>
                    <span style={{ color: CurrentRoute === "surveyAndMaterial" ? GOLD : "white", fontSize: "14px" }}>
                        Survey & Education Material
                    </span>
                </div>
            </div>
            {/* Menu End  */}

            {/* Menu Start  */}
            <div
                onClick={() => navigate("/hep")}
                style={{ width: "250px", height: "50px", display: "flex", cursor: "pointer", borderLeft: CurrentRoute === "hep" ? `3px solid ${GOLD}` : "", background: CurrentRoute === "hep" ? SIDE_BAR_HOVER : "" }}
                className="CanHover"
            >
                <div
                    className="d-flex justify-content-center align-items-center "
                    style={{ width: "60px", height: "100%", }}
                >
                    <img
                        alt="img"

                        src={CurrentRoute === "hep" ? HepWhite : HepWhite}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <div className="d-flex  align-items-center" style={{ flex: 1, }}>
                    <span style={{ color: CurrentRoute === "hep" ? GOLD : "white", fontSize: "14px" }}>
                        Home Exercise Program
                    </span>
                </div>
            </div>

            {/* Menu End  */}

            {/* Menu Start  */}
            <div
                onClick={() => navigate("/fitbit")}
                style={{ width: "250px", height: "50px", display: "flex", cursor: "pointer", borderLeft: CurrentRoute === "fitbit" ? `3px solid ${GOLD}` : "", background: CurrentRoute === "fitbit" ? SIDE_BAR_HOVER : "" }}
                className="CanHover"
            >
                <div
                    className="d-flex justify-content-center align-items-center "
                    style={{ width: "60px", height: "100%", }}
                >
                    <img
                        alt="img"

                        src={CurrentRoute === "fitbit" ? FitbitWhite : FitbitWhite}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <div className="d-flex  align-items-center" style={{ flex: 1, }}>
                    <span style={{ color: CurrentRoute === "fitbit" ? GOLD : "white", fontSize: "14px" }}>
                        Fitbit Health
                    </span>
                </div>
            </div>
            {/* Menu End  */}
            <div
            // hidden={UserData?.ClinicGroupItem == null}
                onClick={(e) => {
                    if (!IsSideBarOpen) {
                        handleClick(e);
                        setMenuType("schedule");
                        return;
                    }
                    setScheduleOpen(!ScheduleOpen);
                }}
                style={{ width: "250px", height: "50px", display: "flex", cursor: "pointer", borderLeft: CurrentRoute === "" ? `3px solid ${GOLD}` : "", background: CurrentRoute === "" ? SIDE_BAR_HOVER : "" }}
                className="CanHover"
            >
                <div
                    className="d-flex justify-content-center align-items-center "
                    style={{ width: "60px", height: "100%", }}
                >
                    <img
                        alt="img"
                        src={CurrentRoute === "" ? CalenderWhite : CalenderWhite}
                        style={{ cursor: "pointer", fill: "white" }}
                    />
                </div>
                <div className="d-flex  align-items-center justify-content-between" style={{ flex: 1, paddingRight: "20px" }}>
                    <span style={{ color: CurrentRoute === "" ? GOLD : "white", fontSize: "14px", }}>
                        Schedule
                    </span>
                    <img
                        alt="img" src={CarrotWhite} style={{ transform: `rotate(${AccountOpen ? "180" : "360"}deg)` }} />
                </div>
            </div>
            {
                IsSideBarOpen && ScheduleOpen ? (
                    <>
                        <div
                            // hidden={UserData?.ClinicGroupItem == null}
                            onClick={() => navigate("/book-consultation")}
                            style={{ width: "250px", height: "50px", display: "flex", cursor: "pointer", borderLeft: CurrentRoute === "book-consultation" ? `3px solid ${GOLD}` : "", background: CurrentRoute === "book-consultation" ? SIDE_BAR_HOVER : "" }}
                            className="CanHover"
                        >
                            <div
                                className="d-flex justify-content-center align-items-center "
                                style={{ width: "60px", height: "100%", }}
                            >
                            </div>
                            <div
                                className="d-flex justify-content-center align-items-center "
                                style={{ height: "100%", }}
                            >
                            </div>
                            <div className="d-flex  align-items-center justify-content-between" style={{ flex: 1, paddingRight: "20px" }}>
                                <span style={{ color: CurrentRoute === "book-consultation" ? GOLD : "white", fontSize: "14px", }}>
                                    Book Consultation
                                </span>
                            </div>
                        </div>
                        <div
                            onClick={() => navigate("/upcomingVisit")}
                            style={{ width: "250px", height: "50px", display: "flex", cursor: "pointer", borderLeft: CurrentRoute === "upcomingVisit" ? `3px solid ${GOLD}` : "", background: CurrentRoute === "upcomingVisit" ? SIDE_BAR_HOVER : "" }}
                            className="CanHover"
                        >
                            <div
                                className="d-flex justify-content-center align-items-center "
                                style={{ width: "60px", height: "100%", }}
                            >
                                {/* <img
                                    alt="img"
                                    src={CurrentRoute === "upcomingVisit" ? UCGold : UCWhite}
                                    style={{ cursor: "pointer", fill: "white" }}
                                /> */}
                            </div>
                            <div
                                className="d-flex justify-content-center align-items-center "
                                style={{ height: "100%", }}
                            >
                            </div>
                            <div className="d-flex  align-items-center justify-content-between" style={{ flex: 1, paddingRight: "20px" }}>
                                <span style={{ color: CurrentRoute === "upcomingVisit" ? GOLD : "white", fontSize: "14px", }}>
                                    Upcoming Visits
                                </span>
                            </div>
                        </div>
                    </>
                ) : null
            }




            <div
                onClick={(e) => {
                    if (!IsSideBarOpen) {
                        handleClick(e);
                        setMenuType("account");
                        return;
                    }
                    setAccountOpen(!AccountOpen);
                }

                }
                style={{ width: "250px", height: "50px", display: "flex", cursor: "pointer", borderLeft: CurrentRoute === "" ? `3px solid ${GOLD}` : "", background: CurrentRoute === "" ? SIDE_BAR_HOVER : "" }}
                className="CanHover"
            >
                <div
                    className="d-flex justify-content-center align-items-center "
                    style={{ width: "60px", height: "100%", }}
                >
                    <img
                        alt="img"

                        src={CurrentRoute === "" ? AccountWhite : AccountWhite}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <div className="d-flex  align-items-center justify-content-between" style={{ flex: 1, paddingRight: "20px" }}>
                    <span style={{ color: CurrentRoute === "" ? GOLD : "white", fontSize: "14px", }}>
                        Account
                    </span>
                    <img
                        alt="img" src={CarrotWhite} style={{ transform: `rotate(${AccountOpen ? "180" : "360"}deg)` }} />
                </div>
            </div>
            {
                IsSideBarOpen && AccountOpen ? (
                    <>
                        <div
                            onClick={() => navigate("/myProfile")}
                            style={{ width: "250px", height: "50px", display: "flex", cursor: "pointer", borderLeft: CurrentRoute === "myProfile" ? `3px solid ${GOLD}` : "", background: CurrentRoute === "myProfile" ? SIDE_BAR_HOVER : "" }}
                            className="CanHover"
                        >
                            <div
                                className="d-flex justify-content-center align-items-center "
                                style={{ width: "60px", height: "100%", }}
                            >
                                {/* <img
                                    alt="img"
                                    src={CurrentRoute === "" ? CalenderWhite : CalenderWhite}
                                    style={{ cursor: "pointer", fill: "white" }}
                                /> */}
                            </div>
                            <div
                                className="d-flex justify-content-center align-items-center "
                                style={{ height: "100%", }}
                            >
                            </div>
                            <div className="d-flex  align-items-center justify-content-between" style={{ flex: 1, paddingRight: "20px" }}>
                                <span style={{ color: CurrentRoute === "myProfile" ? GOLD : "white", fontSize: "14px", }}>
                                    My Profile
                                </span>
                            </div>
                        </div>
                        <div
                            onClick={() => navigate("/providers")}
                            style={{ width: "250px", height: "50px", display: "flex", cursor: "pointer", borderLeft: CurrentRoute === "providers" ? `3px solid ${GOLD}` : "", background: CurrentRoute === "providers" ? SIDE_BAR_HOVER : "" }}
                            className="CanHover"
                        >
                            <div
                                className="d-flex justify-content-center align-items-center "
                                style={{ width: "60px", height: "100%", }}
                            >
                                {/* <img
                                    alt="img"
                                    src={CurrentRoute === "" ? CalenderWhite : CalenderWhite}
                                    style={{ cursor: "pointer", fill: "white" }}
                                /> */}
                            </div>
                            <div
                                className="d-flex justify-content-center align-items-center "
                                style={{ height: "100%", }}
                            >
                            </div>
                            <div className="d-flex  align-items-center justify-content-between" style={{ flex: 1, paddingRight: "20px" }}>
                                <span style={{ color: CurrentRoute === "providers" ? GOLD : "white", fontSize: "14px", }}>
                                    Providers
                                </span>
                            </div>
                        </div>
                    </>
                ) : null
            }

            {/* Menu End  */}

            {/* Menu Start  */}
            {/* <div
                onClick={() => navigate("/dashboard")}
                style={{ width: "250px", height: "50px", display: "flex", cursor: "pointer", borderLeft: CurrentRoute === "" ? `3px solid ${GOLD}` : "", background: CurrentRoute === "" ? SIDE_BAR_HOVER : "" }}
                className="CanHover"
            >
                <div
                    className="d-flex justify-content-center align-items-center "
                    style={{ width: "60px", height: "100%", }}
                >
                    <img
                        alt="img"

                        src={CurrentRoute === "" ? HelpGold : HelpWhite}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <div className="d-flex  align-items-center" style={{ flex: 1, }}>
                    <span style={{ color: CurrentRoute === "" ? GOLD : "white", fontSize: "14px" }}>
                        Help
                    </span>
                </div>
            </div> */}
            {/* Menu End  */}

            {/* Menu Start  */}
            <div
                onClick={() => dispatch(LogoutSuccess(true))}
                style={{ width: "250px", height: "50px", display: "flex", cursor: "pointer", borderLeft: CurrentRoute === "" ? `3px solid ${GOLD}` : "", background: CurrentRoute === "" ? SIDE_BAR_HOVER : "" }}
                className="CanHover"
            >
                <div
                    className="d-flex justify-content-center align-items-center "
                    style={{ width: "60px", height: "100%", }}
                >
                    <img
                        alt="img"

                        src={Logout}
                        style={{ cursor: "pointer" }}
                    />
                </div>
                <div className="d-flex  align-items-center" style={{ flex: 1, }}>
                    <span style={{ color: CurrentRoute === "" ? GOLD : "white", fontSize: "14px" }}>
                        Logout
                    </span>
                </div>
            </div>
            {/* Menu End  */}

            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "basic-button",
                }}

            >
                {
                    MenuType === "account" ? (
                        <>
                            <MenuItem onClick={() => {
                                navigate("/myProfile");
                                handleClose();
                            }}>
                                My Profile
                            </MenuItem>
                            <MenuItem onClick={() => {
                                navigate("/providers");
                                handleClose();
                            }}>
                                My Providers
                            </MenuItem>
                        </>
                    ) : null
                }
                {
                    MenuType === "schedule" ? (
                        <>
                            <MenuItem onClick={() => {
                                navigate("/book-consltation");
                                handleClose();
                            }}>
                                Book Consultation
                            </MenuItem>
                            <MenuItem onClick={() => {
                                navigate("/upcomingVisit");
                                handleClose();
                            }}>
                                Upcoming Visits
                            </MenuItem>
                        </>
                    ) : null
                }
                {
                    MenuType === "HealthServices" ? (
                        <>
                            {AllJoinedPrograms.map((value, index) => {
                                if (!value.programId) return null;
                                return (
                                    <MenuItem
                                        onClick={() => {
                                            navigate("/ongoing-program/" + value.programId);
                                            handleClose();
                                        }}
                                        key={index}
                                    >
                                        {value.title}
                                    </MenuItem>
                                );
                            })}
                        </>
                    ) : null
                }


            </Menu>
        </div>
    );
};

Sidebar.propTypes = {

};

export default Sidebar;
