import { createSlice } from "@reduxjs/toolkit";

export const CalendarSlice = createSlice({
    name: "Calendar",
    initialState: {
        AvailableSlots: [],
        BookedSlots: [],
        patientList:[],
    },
    reducers: {
        setAvailableSlots: (state, action) => {
            state.AvailableSlots = action.payload;
        },
        setBookedSlots: (state, action) => {
            state.BookedSlots = action.payload;
        },
        setPatientList: (state, action) => {
            state.patientList = action.payload;
        },
    },
});

export const { setAvailableSlots, setBookedSlots, setPatientList } = CalendarSlice.actions;

export const CalendarReducer = CalendarSlice.reducer;
