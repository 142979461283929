import React, { useEffect, useState } from "react";
import { DARK_BLUE } from "../../constants";
import playBlue from "../../assests/icons/playBlue.svg";
import moment from "moment";
import HepFullScreenNew from "../../common/hep/HepFullScreenNew";
import DialogWrapper from "../../helpers/DialogWrapper";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

const daysArr = [
  {
    label: "Sun",
    value: 4,
  },
  {
    label: "Mon",
    value: 8,
  },
  {
    label: "Tues",
    value: 16,
  },
  {
    label: "Wed",
    value: 32,
  },
  {
    label: "Thurs",
    value: 64,
  },
  {
    label: "Fri",
    value: 128,
  },
  {
    label: "Sat",
    value: 256,
  },
];

const DashboardHEP = ({ SelectedModule, SelectedModuleData }) => {
  const [FullScreenEnables, setFullScreenEnabled] = useState(false);
  const [LinkName, setLinkName] = useState("");
  const [IsInstalled, setIsInstalled] = useState(0);
  const [LinkForView, setLinkForView] = useState("");
  const [DialogOpen, setDialogOpen] = useState("");

  const [DatesArr, setDatesArr] = useState([]);

  useEffect(() => {
    if (SelectedModule) {
      const startDate = SelectedModule.moduleStartDate * 1000;
      const endDate = SelectedModule.moduleEndDate
        ? SelectedModule.moduleEndDate * 1000
        : moment(SelectedModule.moduleStartDate * 1000).add("days", 14);
      let allDates = [];
      let momentStartDate = moment(startDate).toISOString();
      let momentEndDate = moment(endDate).toISOString();
      let keepLoop = true;

      while (keepLoop) {
        if (moment(momentStartDate).isSameOrBefore(momentEndDate)) {
          allDates.push(moment(momentStartDate).format("YYYY-MM-DD"));
          momentStartDate = moment(momentStartDate).add(1, "days");
        } else {
          keepLoop = false;
        }
      }
      // Check if lengthUnit is "One Time" and remove the last element
      if (SelectedModule?.lengthUnit === "One Time" && allDates.length > 0) {
        allDates.shift();
      }
      setDatesArr(allDates);
    }
  }, [SelectedModule]);

  function getColor(num) {
    if (num > 0 && num < 1) return "#ff9e16"; // orange
    if (num == 1) return "#00acad"; // green
    if (num == 0) return "#ccc";
  }

  if (FullScreenEnables)
    return (
      <HepFullScreenNew
        backToMenu={() => {
          setFullScreenEnabled(false);
          window.location.reload();
        }}
        data={SelectedModuleData || []}
        repeat={1}
        treatmentId={
          SelectedModuleData.length > 0 && SelectedModuleData[0].treatmentId
        }
      />
    );

  console.log(SelectedModuleData[0]?.timePerWkTypeId, "==");
  return (
    <div className="col-lg-7 col-md-12 mb-2">
      <div className="card" style={{ border: 0 }}>
        <div
          className="d-flex align-items-center justify-content-between py-3 px-3"
          style={{
            fontSize: "14px",
            fontWeight: "500",
            borderBottom: "1px solid #e4ebf3",
          }}
        >
          <span
            style={{ color: DARK_BLUE, fontSize: "16px", fontWeight: "500" }}
          >
            {SelectedModule.moduleTitle}
          </span>
          <div className="d-flex align-items-center">
            <span
              className="mx-1"
              style={{ color: DARK_BLUE, fontSize: "16px", fontWeight: 500 }}
            >
              Status:
            </span>
            <div>
              {DatesArr.map((value, index) => {
                return (
                  <FiberManualRecordIcon
                    style={{
                      fontSize: "12px",
                      color:
                        SelectedModule.programModuleStatus &&
                        SelectedModule.programModuleStatus[value]
                          ? getColor(
                              parseFloat(
                                SelectedModule.programModuleStatus[value]
                              )
                            )
                          : "#eee",
                    }}
                    key={index}
                  />
                );
              })}
            </div>
          </div>
          <div>
            <span
              className="mx-1"
              style={{ color: DARK_BLUE, fontSize: "16px", fontWeight: 500 }}
            >
              Play:
            </span>
            <img
              onClick={() => setFullScreenEnabled(true)}
              alt="img"
              src={playBlue}
              className="ml-3"
              style={{ cursor: "pointer", height: "30px" }}
            />
          </div>
        </div>
        <div className="row py-2 px-3">
          <div
            className="col-6"
            style={{ color: "#333333", fontWeight: "500", fontSize: "14px" }}
          >
            Start Date:
          </div>
          <div className="col-6" style={{ color: "#333333", fontSize: "14px" }}>
            {moment(SelectedModule.moduleStartDate * 1000).format(
              "MMMM DD, YYYY"
            )}
          </div>
          {SelectedModule.lengthUnit != "One Time" ? (
            <>
              <div
                className="col-6"
                style={{
                  color: "#333333",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                End Date:
              </div>
              <div
                className="col-6"
                style={{ color: "#333333", fontSize: "14px" }}
              >
                {moment(SelectedModule.moduleEndDate * 1000).format(
                  "MMMM DD, YYYY"
                )}
              </div>
            </>
          ) : null}
          {SelectedModuleData[0]?.timePerWkTypeId?.length == 0 ? null : (
            <>
              <div
                className="col-6"
                style={{
                  color: "#333333",
                  fontWeight: "500",
                  fontSize: "14px",
                }}
              >
                Days per Week:
              </div>
              <div
                className="col-6"
                style={{ color: "#333333", fontSize: "14px" }}
              >
                {/* {
                            SelectedModuleData.length > 0 && SelectedModuleData[0].timePerWkTypeId == "508" ? "Sat, Sun, Mon, Tue, Wed, Thur, Fri" : ""
                        }
                        {
                            SelectedModuleData.length > 0 && SelectedModuleData[0].timePerWkTypeId == "340" ? "Sun, Tues, Thur, Sat" : ""
                        }
                        {
                            SelectedModuleData.length > 0 && SelectedModuleData[0].timePerWkTypeId == "248" ? "Sat, Sun" : ""
                        }
                        {
                            SelectedModuleData.length > 0 && SelectedModuleData[0].timePerWkTypeId < 4 ? "Sun" : ""
                        } */}
                {SelectedModuleData[0]?.timePerWkTypeId.map((value, index) => (
                  <span key={index}>
                    {value}
                    {index !==
                      SelectedModuleData[0]?.timePerWkTypeId?.length - 1 && ","}
                  </span>
                ))}
                {/* {
                                    SelectedModuleData.length > 0 && daysArr?.map((value) => SelectedModuleData[0].timePerWkTypeId & value.value)?.map((value, index) => {
                                        if (value === 0) return null;
                                        return <span key={index} > {daysArr[index].label},{" "} </span>;
                                    })
                                } */}
              </div>
            </>
          )}
          <div
            className="col-6"
            style={{
              color: "#333333",
              fontWeight: "500",
              fontSize: "14px",
            }}
          >
            Repeat For:
          </div>
          <div className="col-6" style={{ color: "#333333", fontSize: "14px" }}>
            {SelectedModule.lengthUnit}
          </div>
          <div
            className="col-6"
            style={{ color: "#333333", fontWeight: "500", fontSize: "14px" }}
          >
            Times a Day:
          </div>
          <div className="col-6" style={{ color: "#333333", fontSize: "14px" }}>
            {SelectedModuleData.length > 0 &&
              SelectedModuleData[0].timePerDayTypeId}
          </div>
          <div
            className="col-6"
            style={{ color: "#333333", fontWeight: "500", fontSize: "14px" }}
          >
            Repeat Circuit - Rest Time Between Circuit (secs):
          </div>
          <div className="col-6" style={{ color: "#333333", fontSize: "14px" }}>
            {SelectedModuleData.length > 0 && SelectedModuleData[0].repeatNumb}{" "}
            -{" "}
            {SelectedModuleData.length > 0 &&
              SelectedModuleData[0].holdinterval}{" "}
            sec
          </div>
        </div>
        <div className="row px-3 mt-3 mb-5">
          {SelectedModuleData.map((value, index) => {
            return (
              <div key={index} className="col-lg-4 col-md-6 col-sm-12 mt-2">
                <span
                  style={{
                    color: DARK_BLUE,
                    fontSize: "16px",
                    fontWeight: 500,
                  }}
                >
                  {index + 1}. {value.videoName}
                </span>
                <div
                  onClick={() => {
                    setLinkName(value.videoName);
                    setLinkForView(value.filename);
                    setIsInstalled(value.isInstalled);
                    setDialogOpen(true);
                  }}
                  style={{
                    height: "140px",
                    width: "100%",
                    borderRadius: "18px",
                    backgroundImage:
                      "url(https://dfi4wfwfroz6l.cloudfront.net/" +
                      value?.image +
                      ")",
                    backgroundSize: "cover",
                    cursor: "pointer",
                  }}
                  className="d-flex justify-content-center align-items-center mt-2 mb-2"
                >
                  <img
                    alt="img"
                    src={playBlue}
                    style={{ width: "30px", cursor: "pointer" }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <DialogWrapper
        open={DialogOpen}
        onClose={() => setDialogOpen(false)}
        maxWidth="lg"
        title={LinkName}
        NoFooter={true}
        content={
          <div
            style={{
              width: "100%",
              height: "80vh",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <video
              alt="materialVideo"
              style={{ width: "100%" }}
              controls
              autoPlay
            >
              {IsInstalled == 1 ? (
                <source
                  src={`https://d3i9v5bkcvmkzb.cloudfront.net/${LinkForView}`}
                  type="video/mp4"
                ></source>
              ) : (
                <source
                  src={`https://d1rctz31urrh7u.cloudfront.net/${LinkForView.replace(
                    ".mp4",
                    "_1024.mp4"
                  )}`}
                  type="video/mp4"
                ></source>
              )}
            </video>
          </div>
        }
      />
    </div>
  );
};

export default DashboardHEP;
