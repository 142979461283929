import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import MenuIcon from "../../assests/icons/menu.svg";
import EngageLogo from "../../assests/images/restooreLogo.svg";
import Carrot from "../../assests/icons/carrot.svg";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import * as Actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { LogoutSuccess } from "../../store/reducers";


const Navbar = props => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const hideActions = props.hideActions;

    const [anchorEl, setAnchorEl] = React.useState(null);
    const [Photo, setPhoto] = useState("");


    const UserData = useSelector(state => state.User.UserData);
    const ClinicInfo = useSelector(state => state.User.ClinicInfo);

    useEffect(() => {
        setPhoto(UserData?.photo || "");
    }, [UserData?.photo]);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };



    return (
        <nav
            className="w-100 h-100 d-flex "
            style={{ background: "white", boxShadow: "0 2px 2px 0 rgb(204 204 204 / 50%)" }}
        >

            {
                hideActions ? null : (
                    <div
                        hidden={hideActions}
                        className="h-100 d-flex justify-content-center align-items-center "
                        style={{ width: "60px", }}
                    >
                        <img
                            alt="menu"
                            onClick={() => dispatch(Actions.openCloseSideBar())}
                            src={MenuIcon}
                            style={{ cursor: "pointer" }}
                        />
                    </div>

                )
            }

            <div
                className="d-flex align-items-center"
                style={{ flex: "1", paddingLeft: hideActions ? "10px" : "" }}
            >
                {
                    ClinicInfo?.logoimg ? (
                        <img
                            alt="logo"
                            src={"https://bluejayprofileimg.s3.amazonaws.com/" + ClinicInfo?.logoimg}
                            style={{ height: "33px", cursor: "pointer" }}
                            onClick={() => navigate("/dashboard")}
                        />
                    ) : (
                        <img
                            alt="logo"
                            src={EngageLogo}
                            style={{ height: "33px", cursor: "pointer" }}
                            onClick={() => navigate("/dashboard")}
                        />
                    )
                }

            </div>
            <div
                className="d-flex align-items-center"
                style={{ width: "250px", }}
            >
                <div
                    style={{ height: "50%", borderLeft: "1px solid #e4ebf3" }}
                />
                <div
                    className=" d-flex justify-content-center align-items-center overflow-hidden"
                    style={{
                        borderRadius: "100%",
                        height: "45px",
                        width: "45px",
                        marginLeft: "20px",
                        marginRight: "20px",
                        // border:"1px solid red",
                        backgroundImage: `url(${UserData?.photo ? `https://d31dgoc2eniy0p.cloudfront.net/${UserData?.photo}` : `https://ui-avatars.com/api/?name=${UserData?.firstName.replaceAll(" ", "+")}+${UserData?.lastName.replaceAll(" ", "+")}`})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat"
                    }}
                >
                    {/* <img
                        alt="user img"
                        src={UserData?.photo ? `https://d31dgoc2eniy0p.cloudfront.net/${UserData?.photo}` : `https://ui-avatars.com/api/?name=${UserData?.firstName}+${UserData?.lastName}`}
                        style={{ height: "100%" }}
                    /> */}

                </div>
                <span
                    onClick={handleClick}
                    className="d-flex align-items-center"
                    style={{ cursor: hideActions ? "" : "pointer", height: "100%" }}
                >
                    {UserData?.firstName} {UserData?.lastName}  <img alt="icon" hidden={hideActions} className="ml-3 mr-3" src={Carrot} />
                </span>

                <Menu
                    hidden={hideActions}
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => {

                        navigate("/myProfile");
                        setAnchorEl(null);
                    }}>My Profile</MenuItem>
                    <MenuItem onClick={() => {
                        dispatch(LogoutSuccess(true));
                        setAnchorEl(null);
                    }}>Logout</MenuItem>
                </Menu>
            </div>
        </nav>
    );
};

Navbar.propTypes = {

};

export default Navbar;
