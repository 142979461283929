import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { getBaseURL } from "../../helpers/getBaseURL";
import * as ReducerActions from "../reducers/";
import { getParams, getUserID } from "./uiContent.actions";

const urlParams = new URLSearchParams(window.location.search);
const userId = urlParams.get("userId");
const clinicGroupItemId = urlParams.get("clinicGroupItemId");

export const GetAllPrograms = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${getBaseURL()}/ProgramByClinicId/${
        clinicGroupItemId ||
        getState().User.UserData?.ClinicGroupItem?.clinicGroupItemId || 0
      }/${getParams(getState)}`
    );

    dispatch(ReducerActions.setPrograms(res.data));

    const res2 = await axios.get(
      `${getBaseURL()}/ProgramByUserId/${
        userId || getUserID(getState)
      }/${getParams(getState)}`
    );

    let joinedPrograms = res2.data;
    let joinedProgramsWithDate = [];

    if (joinedPrograms.length > 0) {
      for (let index = 0; index < joinedPrograms.length; index++) {
        const element = joinedPrograms[index];

        const modules = await dispatch(
          PatientProgramDetailByUserIdProgIdGiveDate(
            element.programId,
            userId || getUserID(getState),
            element.startDate
          )
        );
        // if (modules.length > 0) {

        // CurrentProgram.Modules.filter(task => moment(task.moduleStartDate * 1000).format("YYYY-MM-DD") === CurrentDate)

        const newDates = modules.map((value) =>
          moment(value.moduleStartDate * 1000).format("YYYY-MM-DD")
        );

        let modulesWithDatesObj = {};

        for (let index2 = 0; index2 < newDates.length; index2++) {
          const element2 = newDates[index2];
          let modulesWithDates = modules.filter(
            (task) =>
              moment(task.moduleStartDate * 1000).format("YYYY-MM-DD") ===
              element2
          )[0];
          modulesWithDatesObj[element2] = modulesWithDates;
        }
        let obj = {
          ...modules[0],
          ...element,
          Modules: modules,
          Dates: newDates,
          ModulesWithDatesObj: modulesWithDatesObj,
          DatesInTimeStamps: modules.map((value) => value.moduleStartDate),
        };
        joinedProgramsWithDate = [...joinedProgramsWithDate, obj];
        // }
      }

      dispatch(
        ReducerActions.setAllJoinedProgramsFromThisUser(joinedProgramsWithDate)
      );
    }

    return joinedProgramsWithDate;

    // work on including dates

    // let joinedPrograms = [];
    // const joinedProgramsArr = await dispatch(GetAllprogramsJoinedByTheLoggedInUser());

    //     if (joinedPrograms.length > 0) {
    //         dispatch(ReducerActions.setAllJoinedProgramsFromThisUser(joinedPrograms));
    //     }
    // }
  } catch (error) {
    console.log(error);
  }
};

export const PatientProgramDetailByUserIdProgIdGiveDate =
  (progId, userId, giveDate) => async (dispatch, getState) => {
    const res = await axios.get(
      `${getBaseURL()}/ProgramDetailByProgramId/${progId}${getParams(getState)}`
    );
    return res.data;
  };

export const GetAllProgramsByID =
  (id, returnData = false) =>
  async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${getBaseURL()}/ProgramDetailByProgramId/${id}/${getParams(getState)}`
      );

      if (returnData) {
        return res.data;
      }
      dispatch(ReducerActions.setProgramDetails(res.data));
    } catch (error) {
      console.log(error);
    }
  };

export const getDataWithProgramAndModuleId =
  (pId, mId, returnRes = false) =>
  async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${getBaseURL()}/ModuleDetailByModuleIdModuleTypeId/${pId}/${mId}${getParams(
          getState
        )}`
      );

      if (returnRes) return res.data;
      dispatch(ReducerActions.setModuleDetails(res.data));
    } catch (error) {
      console.log(error);
    }
  };

export const getDataWithProgramAndModuleIdForJoinedPatient =
  (pId, mId) => async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${getBaseURL()}/PatientModuleByModuleIdModuleTypeId/${pId}/${mId}${getParams(
          getState
        )}`
      );

      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

export const JoinProgram =
  (pID, starttime, endTime) => async (dispatch, getState) => {
    try {
      const body = {
        starttime: starttime,
        endTime: endTime,
      };

      const res = await axios.post(
        `${getBaseURL()}/JoinProgram/${getUserID(getState)}/${pID}${getParams(
          getState
        )}`,
        body
      );

      await dispatch(GetAllPrograms);
      return true;
    } catch (error) {
      console.log(error);
    }
  };

export const GetAllprogramsJoinedByTheLoggedInUser =
  () => async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${getBaseURL()}/ProgramClientRelByUserId/${getUserID(
          getState
        )}${getParams(getState)}`
      );

      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

export const markAsComplete =
  (pmId, completionRate) => async (dispatch, getState) => {
    const res = await axios.post(
      `${getBaseURL()}/updateModuleStatus/${getParams(getState)}`,
      {
        programModuleId: pmId,
        completionRate: completionRate,
        logTime: moment().unix(),
      }
    );
  };
