import { getBaseURL } from "../../helpers/getBaseURL";
import axios from "axios";
import { getUserID, getParams } from ".";
import moment from "moment";
import "moment-timezone";

const timezone = moment.tz.guess();

export const getStates = () => {
    return async (dispatch) => {
        try {
            const res = await axios.get(
                `${getBaseURL()}/getFullStateListForCountry/US`
            );

            return res;
        } catch (error) {
            console.error(error);
        }
    };
};

export const searchAdminDoctors = (
    selectedState,
    searchText,
    pageNo,
    perPage = 12
) => {
    return async (dispatch, getState) => {
        try {
            const res = await axios.post(
                `${getBaseURL()}/searchAdminDoctors${getParams(getState)}`,
                {
                    state: selectedState,
                    search: searchText,
                    pageNo: pageNo,
                    perPage: perPage,
                }
            );

            return res.data;
        } catch (error) {
            console.error(error);
        }
    };
};

export const getAdminDocFreeSlots = (adminDocterId, date) => {
    return async (dispatch, getState) => {
        try {
            const res = await axios.post(
                `${getBaseURL()}/getAdminDocFreeSlots${getParams(getState)}`,
                {
                    adminDoctorId: adminDocterId,
                    startDate: date,
                    endDate: date,
                    timezone: timezone,
                }
            );

            return res.data;
        } catch (error) {
            console.error(error);
        }
    };
};

export const bookConsultation = (body) => {
    return async (dispatch, getState) => {
        try {
            const res = await axios.post(
                `${getBaseURL()}/bookedAppointment${getParams(getState)}`,
                body
            );

            return res.data;
        } catch (error) {
            console.error(error);
        }
    };
};
export const getPatientClinicProviders = (body) => {
    return async (dispatch, getState) => {
        try {
            const res = await axios.post(
                `${getBaseURL()}/getPatientClinicProviders${getParams(getState)}`,
                body
            );

            return res.data;
        } catch (error) {
            console.error(error);
        }
    };
};
export const getPatientPublicProviders = (body) => {
    return async (dispatch, getState) => {
        try {
            const res = await axios.post(
                `${getBaseURL()}/searchProvidersForNonClinicPatient${getParams(getState)}`,
                body
            );
            return res.data;
        } catch (error) {
            console.error(error);
        }
    };
};
