import axios from "axios";
import { getBaseURL, getOnlyBaseURL } from "../../helpers/getBaseURL";
import * as ReducerActions from "../reducers/";
import { getParams, getUserID } from "./uiContent.actions";
import moment from "moment";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { getNextDay } from "../../helpers/dates";
import generateTimeline from "../../helpers/getTimeline";
// import { useLocation } from "react-router-dom";

// var search = useLocation().search;
// var clinicGroupItemId = new URLSearchParams(search).get("clinicGroupItemId");
const urlParams = new URLSearchParams(window.location.search);
const clinicGroupItemId = urlParams.get("clinicGroupItemId");
const doctorAdminId = urlParams.get("doctorAdminId");

export const saveRTMEditModule =
  (
    CPTCodeId,
    cptCode,
    OldBillingCycle,
    billingCycle,
    daysCount,
    touchPoints,
    notes,
    price,
    userId,
    programId
  ) =>
  async (dispatch, getState) => {
    try {
      const body = {
        CPTCodeId: CPTCodeId,
        adminDocterId: doctorAdminId,
        cptCode: cptCode,
        OldBillingCycle: OldBillingCycle,
        billingCycle: billingCycle,
        daysCount: daysCount,
        touchPoints: touchPoints,
        notes: notes,
        price: price,
        userId: userId,
        programId: programId,
      };

      const res = await axios.post(
        `${getBaseURL()}/RTMBillingEdit${getParams()}`,
        body
      );

      if (res?.data?.status) {
        toast.success(res?.data?.message || "Updated successfully!");
      } else {
        toast.error(res?.data?.message || "Failed!");
      }

      return res?.data?.status || false;
    } catch (error) {
      console.log(error);
    }
  };

export const revertRTM = (accessKey) => async (dispatch, getState) => {
  try {
    const body = {
      accessKey,
    };

    // const res = await axios.post(`${getBaseURL()}/getFreeSlotsByDoctorIdClinicId`, body);
    const res2 = await axios.post(
      `${getBaseURL()}/RevertRTMTouchPointModifications${getParams()}`,
      body
    );

    return;
    // dispatch(ReducerActions.setSlots(res.data));
  } catch (error) {
    console.log(error);
    dispatch(ReducerActions.setSlots([]));
  }
};

export const getAvailableTimeSlotsForRTM =
  (startDate) => async (dispatch, getState) => {
    try {
      if (!startDate) return;

      const body = {
        doctorId: doctorAdminId,
        clinicGroupItemId: clinicGroupItemId,
        startDate: moment(startDate).format("YYYY-MM-DD 00:00:00 ZZ"),
        timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };

      // const res = await axios.post(`${getBaseURL()}/getFreeSlotsByDoctorIdClinicId`, body);
      const res2 = await axios.post(
        `${getBaseURL()}/getFreeSlotsAndBookedSlotsByDoctorId${getParams()}`,
        body
      );
      // console.log(res2, 'response');
      return res2.data;
      // dispatch(ReducerActions.setSlots(res.data));
    } catch (error) {
      console.log(error);
      dispatch(ReducerActions.setSlots([]));
    }
  };

export const getSurveyReportDetails = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${getBaseURL()}/getSurveyReportDetails${getParams()}&REPORT_COUNT=4&SURVEY_TYPE=SURVEY&otherLanguage=0&sypLogTypeId=259&userId=${urlParams.get(
        "userId"
      )}`
    );

    if (!res.data.QUESANSWERS) return null;
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

// Get RTM Codes
// @url GET /RTMCode

export const getRTMCodes = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${getBaseURL()}/RTMCode${getParams(getState)}`
    );

    if (res.data.error) {
      return res.data;
    }

    dispatch(ReducerActions.setRTMData(res.data || []));

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

// Post RTM Programs By User ID
// @url POST /RTMProgramByUserId/$userId

export const getRTMPrograms = (userId) => async (dispatch, getState) => {
  try {
    const body = {
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
    };
    let res = await axios.post(
      `${getBaseURL()}/RTMProgramByUserId/${userId}/${getParams(getState)}`,
      body
    );

    if (res.data.error) {
      return res.data;
    }

    const timeline = generateTimeline(
      res?.data[0]?.startDate,
      res?.data[0]?.endDate
    );

    res.data[0].timeline = timeline;

    dispatch(ReducerActions.setRTMProgramsData(res.data || []));
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

// Get RTM Programs Modules By Task ID
// * @url GET /RTMProgramModulesByProgramId/$programId

export const getRTMProgramModulesByProgramId =
  (programId, timeline) => async (dispatch, getState) => {
    try {
      const body = {
        timeline: timeline,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      const res = await axios.post(
        `${getBaseURL()}/RTMProgramModulesByProgramId/${programId}/${getParams(
          getState
        )}`,
        body
      );

      if (res.data.error) {
        return res.data;
      }

      return res.data;
      // dispatch(ReducerActions.setRTMProgramModulesData(res.data || []));
    } catch (error) {
      console.log(error);
    }
  };

// Get RTM Program Detail By Program Id
// * @url GET /ProgramByProgramId/$programId

export const getRTMProgramDetailByProgramId =
  (programId) => async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${getBaseURL()}/ProgramByProgramId/${programId}/${getParams(getState)}`
      );

      if (res.data.error) {
        return res.data;
      }

      dispatch(ReducerActions.setRTMProgramDetailData(res.data || []));
    } catch (error) {
      console.log(error);
    }
  };

// POST Edit RTM Title, description, startDate, EndDate
//  @url POST /Program/$type/$doc_patient_id/$passcode/$token

export const editRTMDetails =
  (
    programId,
    title,
    description,
    startDate,
    endDate,
    SelectedInsurance,
    IsNewInsurance,
    NewInsuranceName,
    SelectedDxCode
  ) =>
  async (dispatch, getState) => {
    try {
      const body = {
        programId: programId,
        title: title,
        description: description,
        startDate: startDate,
        endDate: endDate,
        insuranceLkpId: SelectedInsurance,
        IsNewInsurance: IsNewInsurance,
        NewInsuranceName: NewInsuranceName,
        diagnosisCodeLkpId: SelectedDxCode,
      };

      const res = await axios.post(
        `${getBaseURL()}/UpdateRTM/${getParams(getState)}`,
        body
      );

      toast.success("Updated successfully!");
    } catch (error) {
      console.log(error);
    }
  };

// Get RTM Program modules By ProgramId, CPTCode, NoOfMonth
// * @url GET /ProgramDetailByProgramId/$programId

export const getRTMProgramModulesByProgramCPTNumbOfMonth =
  (programId, cptCodeId, numbOfMonth) => async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${getBaseURL()}/RTMProgramModulesByProgramCPTNumbOfMonth/${programId}/${cptCodeId}/${numbOfMonth}/${getParams(
          getState
        )}`
      );

      if (res.data.error) {
        return res.data;
      }

      dispatch(ReducerActions.setRTMProgramModulesNoOfMonth(res.data || []));

      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

export const getAllDataForCalenderAction =
  (userId, timeline) => async (dispatch, getState) => {
    const Data = [dispatch(getRTMCodes()), dispatch(getRTMPrograms(userId))];
    const res = await Promise.all(Data);
    const RTMCodesData = res[0];
    const RTMProgramsData = res[1];
    const { programId, startDate, endDate } = RTMProgramsData[0];
    const allModulesForThisProgram = await dispatch(
      getRTMProgramModulesByProgramId(programId, timeline)
    );

    // const codesToFilter = ["98975", "98977", "98980", "98981"];

    // let filteredModulesByCode = {};
    let monthsArr = [];
    const startDateMonth = moment(startDate * 1000).format("MMMM");

    const endDateMonth = moment(endDate * 1000).format("MMMM");
    let keepLoop = true;
    let monthIndex = 0;
    while (keepLoop) {
      const month = moment(startDate * 1000)
        .add(monthIndex, "month")
        .format("MMMM");

      if (month === endDateMonth) {
        keepLoop = false;
      }

      monthsArr.push(month);

      monthIndex++;
    }
    const currentMonth = moment().format("MMMM");
    const indexOfCurrentMonth = monthsArr.indexOf(currentMonth) + 1;
    window.parent.postMessage(
      JSON.stringify({
        type: "thisIsNumberOfMonth",

        NoOfMonth: indexOfCurrentMonth,

        ProgramId: programId,
      }),
      "*"
    );

    // // Filter the allModulesForThisProgram array based on the codes
    // codesToFilter.forEach(code => {
    //     const filteredModules = allModulesForThisProgram.filter(module => module.code === code);
    //     filteredModulesByCode[code] = filteredModules;
    // });

    let firstCode = allModulesForThisProgram.filter(
      (item) => item.code === "98977"
    );
    let secondCode = allModulesForThisProgram.filter(
      (item) => item.code === "98975"
    );
    let thirdCode = allModulesForThisProgram.filter(
      (item) => item.code === "98980"
    );
    let fourthCode = allModulesForThisProgram.filter(
      (item) => item.code === "98981"
    );

    let arrays = [firstCode, secondCode, thirdCode, fourthCode];

    let maxLength = arrays.reduce((max, arr) => Math.max(max, arr.length), 0);

    let data = [];

    for (let index = 0; index < maxLength; index++) {
      let rowData = {};
      if (index < firstCode.length) {
        rowData["98977"] = firstCode[index];
      }
      if (index < secondCode.length) {
        rowData["98975"] = secondCode[index];
      }
      if (index < thirdCode.length) {
        rowData["98980"] = thirdCode[index];
      }
      if (index < fourthCode.length) {
        rowData["98981"] = fourthCode[index];
      }
      data.push(rowData);
    }

    dispatch(ReducerActions.setRTMPDataForCalender([]));
    dispatch(ReducerActions.setRTMPDataForCalender(data));
  };

// Get E-Matetial DropDown for AddModule
// same route @url GET /AdminDocterClinicLibrary/$docId
// let docId = 50;
export const AdminDocterClinicLibraryEmaterial =
  (docId) => async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${getBaseURL()}/AdminDocterClinicLibrary/${docId}/${getParams(
          getState
        )}&libraryType=2`
      );

      if (res.data.error) {
        return res.data;
      }

      dispatch(
        ReducerActions.setAdminDocterClinicLibraryEmaterial(res.data || [])
      );

      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

// // Get HEP DropDown for AddModule
// // @url GET /AdminDocterClinicLibrary/$docId
export const AdminDocterClinicLibraryHEP =
  (docId) => async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${getBaseURL()}/AdminDocterClinicLibrary/${docId}/${getParams(
          getState
        )}&libraryType=1`
      );

      if (res.data.error) {
        return res.data;
      }

      dispatch(ReducerActions.setAdminDocterClinicLibraryHEP(res.data || []));

      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

// Get Survey DropDown for AddModule
// @url GET /getSurveyTypeListByClinic
export const getSurveyTypeListByClinic = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${getBaseURL()}/getSurveyTypeListByClinic/${getParams(
        getState
      )}&clinicGroupItemId=${clinicGroupItemId}`
    );

    if (res.data.error) {
      return res.data;
    }

    dispatch(ReducerActions.setSurveyTypeListByClinic(res.data || []));

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const saveRTMProgramModule =
  (moduleState) => async (dispatch, getState) => {
    const thisModule = moduleState[0];

    const res = await axios.post(
      `${getBaseURL()}/AddRTMProgramModule/${getParams(getState)}`,
      thisModule
    );

    toast.success("Module Saved Successfully");
  };

// Get module details on right panal of RTM Calendar
// @url GET /ModuleDetailByModuleIdModuleTypeId/$programModuleId/$moduleTypeLkpId
export const ModuleDetailByModuleIdModuleTypeId =
  (programModuleId, moduleTypeLkpId) => async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${getBaseURL()}/ModuleDetailByModuleIdModuleTypeId/${programModuleId}/${moduleTypeLkpId}/${getParams(
          getState
        )}`
      );

      if (res.data.error) {
        return res.data;
      }

      dispatch(
        ReducerActions.setModuleDetailByModuleIdModuleTypeId(res.data || [])
      );

      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

// Get module details on right panal of RTM Calendar
// @url GET /PatientModuleByModuleIdModuleTypeId/$programModuleId/$moduleTypeLkpId
export const PatientModuleByModuleIdModuleTypeId =
  (programModuleId, moduleTypeLkpId) => async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${getBaseURL()}/PatientModuleByModuleIdModuleTypeId/${programModuleId}/${moduleTypeLkpId}/${getParams(
          getState
        )}`
      );
      if (res.data.error) {
        return res.data;
      }

      dispatch(
        ReducerActions.setPatientModuleByModuleIdModuleTypeId(res.data || [])
      );

      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

// Get data for task category dropdown
// @url GET /TaskCategoryLkp/$id
export const getTaskCategoryLkp = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${getBaseURL()}/TaskCategoryLkp/${getParams(getState)}`
    );

    if (res.data.error) {
      return res.data;
    }

    dispatch(ReducerActions.setTaskCategoryLkp(res.data || []));

    // dispatch(getTaskDataTypeLkp())

    return res.data;
  } catch (error) {
    console.log(error);
  }
};

// Get data for task dataType dropdown
// @url GET /TaskDataTypeLkp/$id
export const getTaskDataTypeLkp = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${getBaseURL()}/TaskDataTypeLkp/${getParams(getState)}`
    );

    if (res.data.error) {
      return res.data;
    }

    dispatch(ReducerActions.setTaskDataTypeLkp(res.data || []));
  } catch (error) {
    console.log(error);
  }
};

// Get data for task dataType dropdown
// @url GET /TaskDataTypeLkp/$id
export const getTaskDataTypeLkpid = (id) => async (dispatch, getState) => {
  return;
  // try {
  //     const res = await axios.get(`${getBaseURL()}/TaskDataTypeLkp/${id}/${getParams(getState)}`);

  //     return res.data;

  // } catch (error) {
  //     console.log(error);
  // }
};

// Delete RTM Module data using programModuleId
//  @url POST /DeleteRTMProgramModule/$programModuleId
export const DeleteRTMProgramModule =
  (programModuleId) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${getBaseURL()}/DeleteRTMProgramModule/${programModuleId}/${getParams(
          getState
        )}`
      );

      if (res.data.error) {
        toast.warning(`${res.data}`);
        return res.data;
      } else {
        toast.success("Module deleted successfully!");
      }
      return;
    } catch (error) {
      console.log(error);
    }
  };

export const saveRTMModuleNew =
  (moduleState, code, month) => async (dispatch, getState) => {
    try {
      const RTMMONTHS = getState().RTMReducer.RTMMonths;

      // for (let index = 0; index < moduleState.length; index++) {
      // const singleModule = moduleState[index];
      // console.clear();
      // console.dir(singleModule);

      // let moduleType = 1;

      // if (singleModule.RadioType === "E-Material") moduleType = 1;
      // if (singleModule.RadioType === "HEP") moduleType = 2;
      // if (singleModule.RadioType === "Survey") moduleType = 3;
      // if (singleModule.RadioType === "Telehealth") moduleType = 4;
      // if (singleModule.RadioType === "Task") moduleType = 5;
      // if (singleModule.RadioType === "Phone Call") moduleType = 6;

      let startDate = moment(moduleState.StartDate).format("YYYY-MM-DD");
      let endDate = moment(moduleState.EndDate).format("YYYY-MM-DD");
      let startTime = moment(moduleState.StartTime).format(" HH:mm:ss Z");
      console.log(moduleState.StartTime, startTime, "0937593475934659834");
      let endTime = moment(moduleState.StartTime)
        .add(moduleState.Duration, "minutes")
        .format(" HH:mm:ss Z");
      startTime = startDate + startTime;
      endTime = endDate + endTime;
      let freqType = moduleState.Freq;

      // const thisRTMMonth = RTMMONTHS.indexOf(moment(singleModule.StartDate).format("MMMM"))
      let startDateUnix;
      if (moduleState.moduleType !== 4) {
        startDateUnix = moment(startDate).unix();
        endDate = moment(endDate).unix();
      }

      if (moduleState.moduleType === 6 || moduleState.moduleType === 4) {
        startTime =
          moment(moduleState.StartDate).format("YYYY-MM-DD") +
          " " +
          moment(moduleState.StartTime).format("HH:mm:ss Z");
        endTime = moment(startTime, "YYYY-MM-DD HH:mm:ss Z")
          .add(moduleState.Duration, "minutes")
          .format("YYYY-MM-DD HH:mm:ss Z");
        freqType = "";
      }

      const body = {
        cptCodeId: code,
        // numbThOfMonth: thisRTMMonth + 1,
        adminDocterUserRelId: urlParams.get("adminDocterUserRelId"),
        userId: urlParams.get("userId"),
        programId: getState().RTMReducer.RTMProgramDetails?.programId,
        clinicLibId: moduleState.DropDownValue,
        startDate: startDateUnix,
        endDate: endTime,
        freqType: moduleState.Freq,
        freqNumb: "1",
        daysPerWeek: moduleState.daysPerWeek,
        timePerWkTypeId: moduleState.timePerWkTypeId,
        moduleTitle: moduleState.ModuleTitle,
        moduleType: moduleState.moduleType,
        moduleDescription: moduleState.TaskDescription,
        taskCategoryId: moduleState.DropDownValue,
        dataTypeId: moduleState.DropDownValue2DataTypeForTask,
        taskTitle: moduleState.TaskTitle,
        starttime: startTime,
        endTime: endTime,
        duration: moduleState.Duration,
        recuring: moduleState.Recurring,
        NumberOfRecurring: moduleState.NumberOfRecurring,
        visitPurpose: "Evaluation",
        roomId: "635784526",
        adminDocterId: urlParams.get("doctorAdminId"),
        note: "",
      };

      // console.clear()
      const res = await axios.post(
        `${getBaseURL()}/AddRTMProgramModule/${getParams(getState)}`,
        body
      );
      if (res.data.status === 0) {
        toast.error(res.data.msg);
      } else {
        toast.success("Module Saved Successfully");
      }

      // }
    } catch (error) {
      toast.error("Error while saving module");
      console.log(error);
    }
  };

export const getPatientModuleByModuleIdModuleTypeIdOneObj =
  (programModuleId, moduleTypeLkpId) => async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${getBaseURL()}/PatientModuleByModuleIdModuleTypeId/${programModuleId}/${moduleTypeLkpId}/${getParams(
          getState
        )}`
      );

      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

// POST Edit Module
//  @url POST /EditProgramModule
//   public function PostEditProgramModule($data)

export const EditProgramModule =
  (data, IsMarkAsComplete) => async (dispatch, getState) => {
    try {
      let moduleType = 1;

      if (data.ModuleTitle === "E-Material") moduleType = 1;
      if (data.ModuleTitle === "HEP") moduleType = 2;
      if (data.ModuleTitle === "Survey") moduleType = 3;
      if (data.ModuleTitle === "Video Visit") moduleType = 4;
      if (data.ModuleTitle === "Task") moduleType = 5;
      if (data.ModuleTitle === "Phone Call") moduleType = 6;

      // const res1 = await axios.get(`${getBaseURL()}/PatientModuleByModuleIdModuleTypeId/${data[0].programModuleId}/${moduleType}/${getParams(getState)}`);
      const res1 = await dispatch(
        getPatientModuleByModuleIdModuleTypeIdOneObj(
          data.programModuleId,
          moduleType
        )
      );

      let myDocData = {};
      if (res1.length > 0) {
        myDocData = res1[0];
      }

      let startDate = moment(data.StartDate).format("YYYY-MM-DD");
      let endDate = moment(data.EndDate).format("YYYY-MM-DD");
      let startTime = moment(data.StartTime).format(" HH:mm:ss Z");
      let endTime = moment(data.StartTime)
        .add(data.Duration, "minutes")
        .format(" HH:mm:ss Z");
      startTime = startDate + startTime;
      endTime = moment(startTime, "YYYY-MM-DD HH:mm:ss")
        .add(data.Duration, "minutes")
        .format("YYYY-MM-DD HH:mm:ss Z"); //endDate + endTime;

      if (moduleType !== 4) {
        startDate = moment(startDate).unix();
        endDate = moment(endDate).unix();
      }
      let body = {
        cptCodeId: data.cptCodeId,
        numbThOfMonth: data.numbThOfMonth,
        adminDocterUserRelId: urlParams.get("adminDocterUserRelId"),
        userId: urlParams.get("userId"),
        programModuleId: data.programModuleId,
        clinicLibId: data.DropDownValue,
        startDate: startDate,
        endDate: endDate,
        freqType: data.Freq,
        freqNumb: "1",
        daysPerWeek: data.daysPerWeek,
        timePerWkTypeId: data.timePerWkTypeId,
        moduleTitle: data.ModuleTitle,
        moduleType: moduleType,
        moduleDescription: data.TaskDescription,
        taskCategoryId: data.DropDownValue,
        taskTitle: data.moduleTitle,
        starttime: startTime,
        endTime: endTime,
        duration: data.Duration,
        recuring: data.Recurring,
        NumberOfRecurring: data.NumberOfRecurring,
        visitPurpose: "Other",
        roomId: "635784526",
        adminDocterId: urlParams.get("doctorAdminId"),
        note: "",
        doctorScheduleId: myDocData.doctorScheduleId,
        bookScheduleId: myDocData.bookScheduleId,
      };

      if (moduleType === 5 || moduleType === 6) {
        body.isCompleted = IsMarkAsComplete;
      }

      const res = await axios.post(
        `${getBaseURL()}/EditProgramModule/${getParams(getState)}`,
        body
      );

      if (res.data.status === 0) {
        toast.error(res.data.msg);
      } else {
        toast.success("Updated successfully!");
      }
    } catch (error) {
      console.log(error);
    }
  };

// Get data for RTM billing
// @url GET /RTMProgramCPTCodeBill
export const getRTMProgramCPTCodeBill =
  (Page, From, To, SearchText, PageSize = 10) =>
  async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${getBaseURL()}/RTMProgramCPTCodeBill/${getParams(
          getState
        )}&search=${SearchText}`,
        {
          search: SearchText,
          from: moment(From).format("YYYY-MM-DD 00:00:00"),
          to: moment(To).format("YYYY-MM-DD 23:59:59"),
          timezone: moment(new Date()).format("Z"),
          timezone_name: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }
      );

      dispatch(ReducerActions.setRTMProgramCPTCodeBill(res.data || []));

      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

export const getSeconds = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${getBaseURL()}/ProgramMonthlyMonitor/${urlParams.get("userId")}/${
        getState().RTMReducer.RTMProgramDetails?.programId
      }${getParams()}`
    );

    dispatch(ReducerActions.setSeconds(res.data.data));
  } catch (error) {
    console.log(error);
  }
};

export const getAllTelehealthModuleByUserId =
  () => async (dispatch, getState) => {
    try {
      const res = await axios.get(
        `${getBaseURL()}/AllTelehealthModuleByUserId/${urlParams.get(
          "userId"
        )}${getParams(getState)}`
      );

      dispatch(ReducerActions.setAllTelehealth(res.data));
    } catch (error) {
      console.log(error);
    }
  };

export const RTMBillingExportCSV =
  (From, To, SearchText, BillingStatusFilter) => async (dispatch, getState) => {
    try {
      const timezone = moment(new Date()).format("Z");
      const from = moment.unix(From).format("YYYY-MM-DD 00:00:00");
      const to = moment.unix(To).format("YYYY-MM-DD 23:59:59");
      const timezone_name = Intl.DateTimeFormat().resolvedOptions().timeZone;
      const billingStatusId = BillingStatusFilter;

      const URL = `${getOnlyBaseURL()}/libs/rtm-billing-csv.php/${getParams(
        getState
      )}&startDate=${from}&endDate=${to}&search=${SearchText}&timezone=${timezone}&timezone_name=${timezone_name}&billingStatusId=${billingStatusId}`;

      window.location.href = URL;
    } catch (error) {
      console.log(error);
    }
  };

export const getRTMEduMaterialCategories = async (getState) => {
  try {
    const res = await axios.get(
      `${getBaseURL()}/AdminDocterClinicLibrary/${urlParams.get(
        "doctorAdminId"
      )}${getParams(getState)}&libraryType=2`
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const getRTMEduMaterialByCategory = async (categoryId, getState) => {
  try {
    const res = await axios.get(
      `${getBaseURL()}/GetFavouriteVideos/${categoryId}/0/${getParams(
        getState
      )}`
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const saveEducationMaterial =
  (moduleState, code, videoIdsArray) => async (dispatch, getState) => {
    try {
      const body = {
        cptCode: code,
        adminDocterUserRelId: urlParams.get("adminDocterUserRelId"),
        userId: urlParams.get("userId"),
        programId: getState().RTMReducer.RTMProgramDetails?.programId,
        moduleTitle: moduleState.moduleTitle,
        enableTimeRange: moduleState.enableTimeRange,
        startDate: moduleState.startDate,
        endDate: moduleState.endDate,
        freqType: moduleState.freqType,
        timePerWkTypeId: moduleState.timePerWkTypeId,
        exerciseVideoIds: videoIdsArray,
        therapyCategoryId: moduleState.therapyCategoryId,
        isContentModified: moduleState.isContentModified,
        content: moduleState.isContentModified ? moduleState.editedContent : moduleState.rtmLetterContent?.content,
        RTMLetterId: moduleState.rtmLetterContent?.id || null
      };
      console.clear();
      const res = await axios.post(
        `${getBaseURL()}/addRTMEducationMaterial${getParams(getState)}`,
        body
      );
      if (res.data.status === 0) {
        toast.error(res.data.msg);
      } else {
        toast.success("Module Saved Successfully");
      }
    } catch (error) {
      toast.error("Error while saving module");
      console.log(error);
    }
  };
export const saveAdditionalActivity =
  (moduleState, code) => async (dispatch, getState) => {
    try {
      const body = {
        cptCode: code,
        adminDocterUserRelId: urlParams.get("adminDocterUserRelId"),
        userId: urlParams.get("userId"),
        programId: getState().RTMReducer.RTMProgramDetails?.programId,
        moduleTitle: moduleState.moduleTitle,
        moduleDescription: moduleState.moduleDescription,
        enableTimeRange: moduleState.enableTimeRange,
        startDate: moduleState.startDate,
        endDate: moduleState.enableTimeRange === 0 ? null : moduleState.endDate,
        freqType: moduleState.freqType,
        timePerWkTypeId: moduleState.timePerWkTypeId,
      };
      const res = await axios.post(
        `${getBaseURL()}/addRTMAdditionalActivity${getParams(getState)}`,
        body
      );
      if (res.data.status === 0) {
        toast.error(res.data.msg);
      } else {
        toast.success("Module Saved Successfully");
      }
    } catch (error) {
      toast.error("Error while saving module");
      console.log(error);
    }
  };

export const getSurveysForRTM = async (getState) => {
  try {
    const res = await axios.get(
      `${getBaseURL()}/getSurveyTypeListByClinic/${getParams(
        getState
      )}&clinicGroupItemId=${clinicGroupItemId}`
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const saveRTMSurvey =
  (moduleState, code, sypLogTypeId) => async (dispatch, getState) => {
    try {
      const body = {
        cptCode: code,
        adminDocterUserRelId: urlParams.get("adminDocterUserRelId"),
        userId: urlParams.get("userId"),
        programId: getState().RTMReducer.RTMProgramDetails?.programId,
        moduleTitle: moduleState.moduleTitle,
        moduleDescription: moduleState.moduleDescription,
        enableTimeRange: moduleState.enableTimeRange,
        startDate: moduleState.startDate,
        endDate: moduleState.endDate,
        freqType: moduleState.freqType,
        timePerWkTypeId: moduleState.timePerWkTypeId,
        sypLogTypeId: sypLogTypeId,
      };
      const res = await axios.post(
        `${getBaseURL()}/addRTMSurvey${getParams(getState)}`,
        body
      );
      if (res.data.status === 0) {
        toast.error(res.data.msg);
      } else {
        toast.success("Module Saved Successfully");
      }
    } catch (error) {
      toast.error("Error while saving module");
      console.log(error);
    }
  };

export const saveRTMHEP =
  (moduleState, code, videosArray, accordianCategories) =>
  async (dispatch, getState) => {
    try {
      const body = {
        cptCode: code,
        adminDocterUserRelId: urlParams.get("adminDocterUserRelId"),
        userId: urlParams.get("userId"),
        programId: getState().RTMReducer.RTMProgramDetails?.programId,
        moduleTitle: moduleState.moduleTitle,
        moduleDescription: moduleState.moduleDescription,
        enableTimeRange: moduleState.enableTimeRange,
        startDate: moduleState.startDate,
        endDate:
          moduleState.enableTimeRange === 0
            ? getNextDay(moduleState.startDate)
            : moduleState.endDate,
        freqType: moduleState.freqType,
        timePerWkTypeId:
          moduleState.freqType !== "Daily" ? moduleState.timePerWkTypeId : [],
        timesADay: moduleState.timesADay || 0,
        videos: videosArray,
        libraryTypeId: accordianCategories.libraryTypeId,
        specialityTypeId: accordianCategories.specialityTypeId,
        categoryId: accordianCategories.categoryId,
        levelId:
          accordianCategories.levelId === null
            ? "0"
            : accordianCategories.levelId,
        subCategoryId: accordianCategories.subCategoryId,
      };
      const res = await axios.post(
        `${getBaseURL()}/addRTMHEP${getParams(getState)}`,
        body
      );
      if (res.data.status === 0) {
        toast.error(res?.data?.errors?.endDate[0]);
      } else {
        toast.success("Module Saved Successfully");
      }
    } catch (error) {
      toast.error("Error while saving module");
      console.log(error);
    }
  };

export const getRTMFavoriteCategory = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${getBaseURL()}/AdminDocterTherapyCategory/${urlParams.get(
        "doctorAdminId"
      )}${getParams(getState)}`
    );
    dispatch(ReducerActions.setFavoriteLibrary([]));
    dispatch(ReducerActions.setFavoriteLibrary(res.data));
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const getRTMBluejayLibrary = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${getBaseURL()}/GetLibrarySpecialityCategoryItems/${getParams(getState)}`
    );
    dispatch(ReducerActions.setBluejayLibraryCategories([]));
    dispatch(ReducerActions.setBluejayLibraryCategories(res.data));
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getRTMBluejayCategory = async (categoryId, getState) => {
  try {
    const res = await axios.get(
      `${getBaseURL()}/GetLibrarySpecialitySubCategoryItems/${categoryId}${getParams(
        getState
      )}`
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const getHEPVideosByCategory = async (
  categoryId,
  level = 0,
  getState
) => {
  try {
    if (!level) {
      level = "0";
    } else {
      level = level === 0 || level === undefined ? "0" : level;
    }
    const res = await axios.get(
      `${getBaseURL()}/GetVideoByCategory/${categoryId}/${level}${getParams(
        getState
      )}`
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getRTMHEPSubCategories = async (
  categoryId,
  level = "0",
  getState
) => {
  try {
    // console.log(level,categoryId, '===')
    if (!level) {
      level = "0";
    } else {
      level = level === 0 || level === undefined ? "0" : level;
    }
    // console.log(`GetLibrarySpecialitySubSubCategoryItems/${categoryId}/${level}${getParams(getState)}`, '===');
    const res = await axios.get(
      `${getBaseURL()}/GetLibrarySpecialitySubSubCategoryItems/${categoryId}/${level}${getParams(
        getState
      )}`
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const getRTMHEPBySessionId = async (
  sessionId,
  level = "0",
  getState
) => {
  try {
    if (!level) {
      level = "0";
    }
    const res = await axios.get(
      `${getBaseURL()}/GetVideoBySessionId/${sessionId}/${level}${getParams(
        getState
      )}`
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const getRTMICDLibrary = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${getBaseURL()}/GetDiagnosisCodeList/${getParams(getState)}`
    );
    dispatch(ReducerActions.setICDCodes([]));
    dispatch(ReducerActions.setICDCodes(res.data));
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const getRTMICDLibraryDiagnosePhase = async (
  mainCategoryId,
  getState
) => {
  try {
    if (mainCategoryId === undefined) {
      mainCategoryId = "1";
    }
    const res = await axios.get(
      `${getBaseURL()}/GetDiagnosePhases/${mainCategoryId}${getParams(
        getState
      )}`
    );
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
export const getRTMICDDXProgramExcercises = async (dxProgramId, getState) => {
  try {
    // console.log(dxProgramId, 'fetching')
    const res = await axios.get(
      `${getBaseURL()}/GetDXProgramExcercises/${dxProgramId}${getParams(
        getState
      )}`
    );
    // console.log(res, 'Diagnosis Code List');
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

export const updateAdditionalActivity =
  (moduleState) => async (dispatch, getState) => {
    try {
      const body = {
        programModuleId: moduleState.programModuleId,
        cptCode: moduleState.cptCode,
        adminDocterUserRelId: urlParams.get("adminDocterUserRelId"),
        userId: urlParams.get("userId"),
        programId: getState().RTMReducer.RTMProgramDetails?.programId,
        moduleTitle: moduleState.moduleTitle,
        moduleDescription: moduleState.moduleDescription,
        enableTimeRange: moduleState.enableTimeRange,
        startDate: moduleState.startDate,
        endDate: moduleState.enableTimeRange == 0 ? null : moduleState.endDate,
        freqType:
          moduleState.enableTimeRange == 0 ? "Daily" : moduleState.freqType,
        timePerWkTypeId:
          moduleState.enableTimeRange == 0 ? [] : moduleState.timePerWkTypeId,
      };
      const res = await axios.post(
        `${getBaseURL()}/updateRTMAdditionalActivity${getParams(getState)}`,
        body
      );
      if (res.data.status === 0) {
        toast.error(res.data.msg);
      } else {
        toast.success("Module Updated Successfully");
      }
    } catch (error) {
      toast.error("Error while updating module");
      console.log(error);
    }
  };

export const updateSurveyRTM = (moduleState) => async (dispatch, getState) => {
  try {
    const body = {
      programModuleId: moduleState.programModuleId,
      cptCode: moduleState.cptCode,
      adminDocterUserRelId: urlParams.get("adminDocterUserRelId"),
      userId: urlParams.get("userId"),
      programId: getState().RTMReducer.RTMProgramDetails?.programId,
      moduleTitle: moduleState.moduleTitle,
      moduleDescription: moduleState.moduleDescription,
      enableTimeRange: moduleState.enableTimeRange,
      startDate: moduleState.startDate,
      endDate: moduleState.enableTimeRange == 0 ? null : moduleState.endDate,
      freqType:
        moduleState.enableTimeRange == 0 ? "Daily" : moduleState.freqType,
      timePerWkTypeId:
        moduleState.enableTimeRange == 0 ? [] : moduleState.timePerWkTypeId,
      sypLogTypeId: moduleState.sypLogTypeId,
    };
    const res = await axios.post(
      `${getBaseURL()}/updateRTMSurvey${getParams(getState)}`,
      body
    );
    if (res.data.status === 0) {
      toast.error(res.data.msg);
    } else {
      toast.success("Module Updated Successfully");
    }
  } catch (error) {
    toast.error("Error while updating module");
    console.log(error);
  }
};

export const updateRTMEducationMaterial =
  (moduleState) => async (dispatch, getState) => {
    try {
      const body = {
        programModuleId: moduleState.programModuleId,
        cptCode: moduleState.cptCode,
        adminDocterUserRelId: urlParams.get("adminDocterUserRelId"),
        userId: urlParams.get("userId"),
        programId: getState().RTMReducer.RTMProgramDetails?.programId,
        moduleTitle: moduleState.moduleTitle,
        moduleDescription: moduleState.moduleDescription,
        enableTimeRange: moduleState.enableTimeRange,
        startDate: moduleState.startDate,
        endDate: moduleState.enableTimeRange == 0 ? null : moduleState.endDate,
        freqType:
          moduleState.enableTimeRange == 0 ? "Daily" : moduleState.freqType,
        timePerWkTypeId:
          moduleState.enableTimeRange == 0 ? [] : moduleState.timePerWkTypeId,
        exerciseVideoIds: moduleState.exerciseVideoIds,
      };
      const res = await axios.post(
        `${getBaseURL()}/updateRTMEducationMaterial${getParams(getState)}`,
        body
      );
      if (res.data.status === 0) {
        toast.error(res.data.msg);
      } else {
        toast.success("Module Updated Successfully");
      }
    } catch (error) {
      toast.error("Error while updating module");
      console.log(error);
    }
  };

export const updateRTMHEP =
  (moduleState, videosArray, accordianCategories) =>
  async (dispatch, getState) => {
    // console.log( accordianCategories.levelId === null, '')
    try {
      const body = {
        programModuleId: moduleState.programModuleId,
        cptCode: moduleState.cptCode,
        adminDocterUserRelId: urlParams.get("adminDocterUserRelId"),
        userId: urlParams.get("userId"),
        programId: getState().RTMReducer.RTMProgramDetails?.programId,
        moduleTitle: moduleState.moduleTitle,
        moduleDescription: moduleState.moduleDescription,
        enableTimeRange: moduleState.enableTimeRange,
        startDate: moduleState.startDate,
        endDate:
          moduleState.enableTimeRange == 0
            ? getNextDay(moduleState.startDate)
            : moduleState.endDate,
        freqType:
          moduleState.enableTimeRange == 0 ? "Daily" : moduleState.freqType,
        timePerWkTypeId:
          moduleState.enableTimeRange == 0 ? [] : moduleState.timePerWkTypeId,
        timesADay: moduleState.timesADay,
        videos: videosArray,
        libraryTypeId: accordianCategories.libraryTypeId,
        specialityTypeId: accordianCategories.specialityTypeId,
        categoryId: accordianCategories.categoryId,
        levelId:
          accordianCategories.levelId === null
            ? "0"
            : accordianCategories.levelId,
        subCategoryId: accordianCategories.subCategoryId,
      };
      const res = await axios.post(
        `${getBaseURL()}/updateRTMHEP${getParams(getState)}`,
        body
      );
      if (res.data.status === 0) {
        toast.error(res?.data?.errors?.endDate[0]);
      } else {
        toast.success("Module Updated Successfully");
      }
    } catch (error) {
      toast.error("Error while saving module");
      console.log(error);
    }
  };

export const savePhoneCallNotes = (moduleData) => async (getState) => {
  try {
    const body = {
      programModuleId: moduleData?.programModuleId,
      description: moduleData?.description,
    };
    const res = await axios.post(
      `${getBaseURL()}/PhoneCallNotes/${getParams(getState)}`,
      body
    );
    if (res.data.status === 0) {
      toast.error(res?.data?.errors);
    } else {
      toast.success("Module Updated Successfully");
    }
  } catch (error) {
    toast.error("Error while saving module");
    console.log(error);
  }
};

export const getRTMLetterNotes = () => async (dispatch, getState) => {
  try {
    const body = { clinicGroupItemId, adminDocterId: doctorAdminId };
    const res = await axios.post(
      `${getBaseURL()}/getRTMLetters/${getParams(getState)}`,
      body
    );
    dispatch(ReducerActions.setRTMLetter([]));
    dispatch(ReducerActions.setRTMLetter(res.data?.data));
  } catch (error) {
    toast.error("Error while getting rtm letters, please refresh");
    console.log(error);
  }
};

export const getSurgeryLibrary = () => async (dispatch, getState) => {
  try {
    const res = await axios.get(
      `${getBaseURL()}/ListSurgeryLibrary/${getParams(getState)}`
    );
    dispatch(ReducerActions.setSurgeryLibrary([]));
    dispatch(ReducerActions.setSurgeryLibrary(res.data));
    return res.data;
  } catch (error) {
    console.log(error);
  }
};
