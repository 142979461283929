import axios from "axios";
import { getUserID, getParams } from ".";
import { getBaseURL } from "../../helpers/getBaseURL";
import * as ReducerActions from "../reducers/";
import moment from "moment";
import "moment-timezone";

const urlParams = new URLSearchParams(window.location.search);
const doctorAdminId = urlParams.get("doctorAdminId");
const docId = urlParams.get("docId");
const timezone = moment.tz.guess();

export const getAdminDocAvailabilities =
  (startDate, endDate) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${getBaseURL()}/getAdminDocAvailabilitiesWithBookedSlots${getParams(
          getState
        )}`,
        {
          adminDoctorId: docId,
          timezone: timezone,
          startDate: startDate,
          endDate: endDate,
        }
      );

      if (!res.data?.status) {
        console.log(res.data?.errors);
        return res.data?.message;
      }

      dispatch(
        ReducerActions.setAvailableSlots(res.data?.data?.docAvailabilities)
      );
      dispatch(ReducerActions.setBookedSlots(res.data?.data?.docBookedSlots));
    } catch (error) {
      console.log(error);
    }
  };

export const setAdminDoctorAvailability =
  (
    startDate,
    availabilityType,
    slots,
    availability = null,
    availabilityId = null
  ) =>
  async (dispatch, getState) => {
    try {
      const endPoint = availabilityId
        ? "updateAdminDoctorAvailability"
        : "setAdminDoctorAvailability";

      const res = await axios.post(
        `${getBaseURL()}/${endPoint}${getParams(getState)}`,
        {
          adminDoctorId: docId,
          timezone: timezone,
          startDate: startDate,
          availabilityType: availabilityType,
          slots: slots,
          availability: availability,
          id: availabilityId,
        }
      );

      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

export const deleteAdminDoctorAvailability =
  (id) => async (dispatch, getState) => {
    try {
      const res = await axios.post(
        `${getBaseURL()}/deleteAdminDoctorAvailability/${id}${getParams(
          getState
        )}`
      );

      return res.data;
    } catch (error) {
      console.log(error);
    }
  };

export const getPatientList = () => async (dispatch, getState) => {
  try {
    const patientList = await axios.get(
      `${getBaseURL()}/GetPatientList/${doctorAdminId}${getParams(getState)}`, 
      {
        headers: {
          'Accept': '*/*',
        }
      }
    );
    dispatch(ReducerActions.setPatientList(patientList.data));
  } catch (error) {
    console.log(error);
  }
};

export const bookappointment = (data) => async (dispatch, getState) => {
  console.log(data, '===')
  try {
    const res = await axios.post(
      `${getBaseURL()}/bookedAppointment/${getParams(getState)}`,
      {
        adminDoctorId: docId,
        timezone: timezone,
        consultType: "Video Visit",
        payerType: "Cash",
        ...data
      }
    );

    return res.data;
  } catch (error) {
    console.log(error);
  }
};
