import axios from "axios";
import { getBaseURL } from "../../helpers/getBaseURL";
import * as ReducerActions from "../reducers/";
import { getParams, getUserID, getToken, getClinicGroupItemId } from "./uiContent.actions";
import moment from "moment";
import { toast } from "react-toastify";



const urlParams = new URLSearchParams(window.location.search);
// const clinicGroupItemId = urlParams.get("clinicGroupItemId");


export const EnrollRTM = (PatientId, RTMEpisodeName, RTMDesc, StartDate, EndDate, SelectedInsurance, IsNewInsurance, NewInsuranceName, SelectedDxCode) => async (dispatch, getState) => {

    try {
        await axios.post(`${getBaseURL()}/JoinRTM/${getParams(getState)}`, {
            "clinicGroupItemId": urlParams.get("clinicGroupItemId"),
            title: RTMEpisodeName,
            description: RTMDesc,
            startDate: moment(StartDate).unix(),
            endDate: moment(EndDate).unix(),
            adminDocterId: getUserID(getState),
            userId: PatientId,
            insuranceLkpId: SelectedInsurance,
            IsNewInsurance: IsNewInsurance,
            NewInsuranceName: NewInsuranceName,
            diagnosisCodeLkpId: SelectedDxCode,
        });

        dispatch(ReducerActions.setEnrolledPatientListData({ id: PatientId }));

        toast.success("Patient Enrolled Successfully");
    } catch (error) {
        if (error?.response?.data?.error?.code == 400) {
            toast.error("Please fill in all the fields.");
        } else {
            console.log(error?.response);
            toast.error("Something went wrong");
        }
    }
};


export const getPatientListData = (TabIndex, SelectedCompany, pageNo = 1) => async (dispatch, getState) => {
    dispatch(ReducerActions.setPatientListData({ patients: [], total: 0 }));
    let patientsArr = [];
    let complianceList = [];
    let outcomes = [];
    let surveys = [];
    let totalPatients = 0;
    let pageCount = 0;

    let dataForApi = {
        type: TabIndex,
        dedug: 1,
        limit: 10,
        pageno: pageNo,
        filter: "",
        complianceFilter: "all",
        treatmentFilter: "all",
        outcomesFilter: "all",
        statusFilter: "all",
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        currenttime: new Date().toISOString(),
        isSolaUser: false,
        selectedClinicId: "",
        isCrossSearchable: 0,
        firstPage: 1,
        lastPage: 0,
        token: getToken(getState),
        passcode: "",
        doctorIdParam: getUserID(getState),
        versionId: moment().unix(),
        isTorrentFeatureEnable: 0,
    };

    if (TabIndex === "company") dataForApi.orgGroupLkpId = SelectedCompany;


    const forPromise = [
        dispatch(callGetPatientListAPI(dataForApi)),
        dispatch(callGetAllPatientCompliancesNewAPI(dataForApi)),
        // dispatch(callGetAllPatientOutcomesAPI(dataForApi)),
        // dispatch(callGetAllPatientSurveyAPI(dataForApi)),
    ];

    const res = await Promise.all(forPromise);


    totalPatients = res[0].data.total_no_patients;
    pageCount = res[0].data.total_page
    patientsArr = [...patientsArr, ...res[0].data.patients];
    complianceList = [...complianceList, ...res[1].data.patients];
    // outcomes = [...outcomes, ...res[2].data.patients];
    // surveys = [...surveys, ...res[3].data.patients];


    for (let index = 0; index < patientsArr.length; index++) {
        patientsArr[index].CompliaceList = complianceList[index];
        // patientsArr[index].OutComesList = outcomes[index];
        // patientsArr[index].SurveyList = surveys[index];
    }

    dispatch(ReducerActions.setPatientListData({ patients: patientsArr, total: totalPatients, pageCount: pageCount }));

};
export const ArchivePatients = (userId, isArchive) => async (dispatch, getState) => {
    try {
        const res = await axios.post(`${getBaseURL()}/ArchivePatients/${getParams(getState)}`, {
            userId: userId,
            isArchived: isArchive,
        });
        if (res?.data?.status === 1) {
            toast.success(res?.data?.message);
            return true
        } else {
            toast.error("Patient is not archived, Please try again");
            return false
        }
    } catch (error) {
        if (error?.response?.data?.error?.code == 400) {
            toast.error("User is not added to archive");
        } else {
            console.log(error?.response);
            toast.error("Something went wrong");
        }
    }
};
export const callGetPatientListAPI = (dataForApi) => async (dispatch, getState) => {
    const patientList = await axios.get(`${getBaseURL()}/GetPatientList`, {
        params: dataForApi
    });
    return patientList;
};

export const callGetAllPatientCompliancesNewAPI = (dataForApi) => async (dispatch, getState) => {
    const compliancesList = await axios.get(`${getBaseURL()}/GetAllPatientCompliancesNew`, {
        params: dataForApi
    });
    return compliancesList;
};

export const callGetAllPatientOutcomesAPI = (dataForApi) => async (dispatch, getState) => {
    const outcomesList = await axios.get(`${getBaseURL()}/GetAllPatientOutcomes`, {
        params: dataForApi
    });
    return outcomesList;
};

export const callGetAllPatientSurveyAPI = (dataForApi) => async (dispatch, getState) => {
    const surveyList = await axios.get(`${getBaseURL()}/GetAllPatientSurvey`, {
        params: dataForApi
    });
    return surveyList;
};

export const getAllCompanies = () => async (dispatch, getState) => {
    try {
        // const res = await axios.get(`${getBaseURL()}/CompanyHospitals/${getClinicGroupItemId()}/${getParams(getState)}`);

        dispatch(ReducerActions.setAllCompanies([]));
    } catch (error) {
        console.log(error);
    }
};

export const getAllThePatientsInDataBaseForThisUser = () => async (dispatch, getState) => {

    try {
        const res = await axios.post(`${getBaseURL()}/SearchGroupPatient/${getUserID(getState)}/health001~AABO/${getToken(getState)}${getParams(getState)}&isTorrentFeatureEnable=0`);

        dispatch(ReducerActions.setAllThePatients(res.data));
    } catch (error) {

    }
};

export const getAllInsuranceComapnies = () => async (dispatch, getState) => {

    try {
        const res = await axios.get(`${getBaseURL()}/InsuranceLkp/${getParams(getState)}`);

        dispatch(ReducerActions.setInsuranceCompaniesData(res.data));
    } catch (error) {
        console.log(error)
    }
};

export const getAllDxCodes = () => async (dispatch, getState) => {

    try {
        const res = await axios.get(`${getBaseURL()}/listFullDxCode/${getParams(getState)}`);

        dispatch(ReducerActions.setDxCodesData(res.data));
    } catch (error) {
        console.log(error)
    }
};