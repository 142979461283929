import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import store from "./store/store";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "lightbox-react/style.css";
import "./styles/styles.scss";
import { ThemeProvider, StyledEngineProvider, createTheme } from "@mui/material/styles";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers';

const theme = createTheme();
const container = document.getElementById("root"); 

const root = ReactDOM.createRoot(container);
root.render(
    // <React.StrictMode >
    <BrowserRouter>
        <Provider store={store}>
            <ToastContainer
                position="top-center"
                autoClose={10000}
                hideProgressBar={false}
            />
            <StyledEngineProvider injectFirst>
                <ThemeProvider theme={theme}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <App />
                    </LocalizationProvider>
                </ThemeProvider>
            </StyledEngineProvider>
        </Provider>
    </BrowserRouter>
    // </React.StrictMode>
);
