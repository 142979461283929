import axios from "axios";
import { getUserID, getParams } from ".";
import { getBaseURL } from "../../helpers/getBaseURL";

export const submitTaskLogProgressData =
  (percentage, tId, accessCode) => async (dispatch, getState) => {
    const res = await axios.post(
      `${getBaseURL()}/submitTaskLogProgressData/${getParams(getState)}`,
      {
        movementV: -1,
        painV: -1,
        complementV: percentage,
        userId: getUserID(getState),
        taskId: tId,
        accessCode: accessCode,
      }
    );
  };
