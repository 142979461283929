import React, { lazy, Suspense, useEffect } from "react";
import { Route, Navigate, Routes, useLocation } from "react-router-dom";
// import PropTypes from 'prop-types'
import { CircularProgress } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import Navbar from "./common/navbar/Navbar";
import Sidebar from "./common/sidebar/Sidebar";
import GoalsAnOutcomes from "./pages/GoalsAnOutcomes";
import SurveyAndMaterial from "./pages/SurveyAndMaterial";
import axios from "axios";
import * as Actions from "./store/actions";
import { LogoutSuccess, setShowMessagesOnly } from "./store/reducers";
import { useParams } from "react-router-dom";
import HepFullScreen from "./common/hep/HepFullScreen";
import { toast } from "react-toastify";
import ChangePasswordDialog from "./components/changePassword/ChangePasswordDialog";
import MyCurrentProgram from "./pages/MyCurrentProgram";

const Login = lazy(() => import("./pages/Login"));
const FitbitHealthData = lazy(() => import("./pages/FitbitHealthData"));
const Dashboard = lazy(() => import("./pages/Dashboard"));
const SquatDetail = lazy(() => import("./pages/SquatDetail"));
const KemtaiPlayer = lazy(() => import("./pages/KemtaiPlayer"));
const SquatResult = lazy(() => import("./pages/SquatResultPage"));
const Messages = lazy(() => import("./pages/Messages"));
const Documents = lazy(() => import("./pages/Documents"));
const ManageClients = lazy(() => import("./pages/ManageClients"));
const EmployeeRegistration = lazy(() => import("./pages/EmployeeRegistration"));
const EmployeeSelfRegistration = lazy(() =>
  import("./pages/EmployeeSelfRegistration")
);
const MyProfile = lazy(() => import("./pages/MyProfile"));
const Providers = lazy(() => import("./pages/Providers"));
const FitBitHealth = lazy(() => import("./pages/FitBitHealth"));
const RTMBillingPage = lazy(() => import("./pages/RTMBilling"));
const RTMEpisodesPage = lazy(() => import("./pages/RTMEpisodes"));
const RtmPatientsDashboard = lazy(() => import("./pages/RtmPatientsDashboard"));
const UpcomingVisits = lazy(() => import("./pages/UpcomingVisits"));
// const RequestVideoConsultation = lazy(() =>
//   import("./pages/RequestVideoConsultation")
// );
const BookConsultation = lazy(() => import("./pages/BookConsultation"));

const Notes = lazy(() => import("./components/dashboard/Notes"));
const HEP = lazy(() => import("./pages/HEP"));
const Programs = lazy(() => import("./pages/Programs"));
const ProgramDetails = lazy(() => import("./pages/ProgramDetails"));
const ForgetPassword = lazy(() => import("./pages/ForgetPassword"));
const WrapperForHepWithoutLogin = lazy(() =>
  import("./components/hep/WrapperForHepWithoutLogin")
);
const WrapperForActivityWithoutLogin = lazy(() =>
  import("./components/activity/WrapperForActivityWithoutLogin")
);
const WrapperForMessagesOnlyWithLogin = lazy(() =>
  import("./components/messages/WrapperForMessagesOnlyWithLogin")
);
const WrapperForOnlyMaterial = lazy(() =>
  import("./components/surveyAndMaterial/WrapperForOnlyMaterial")
);
const WrapperForOnlySurvey = lazy(() =>
  import("./components/surveyAndMaterial/WrapperForOnlySurvey")
);
const WrapperForOnlyCompliance = lazy(() =>
  import("./components/surveyAndMaterial/WrapperForOnlyCompliance")
);
const AllProgramsForEngage = lazy(() => import("./pages/AllProgramsForEngage"));
const SurveyPopup = lazy(() => import("./pages/SurveyPopup"));
const ICD10Library = lazy(() => import("./pages/ICD10Library"));
const Calendar = lazy(() => import("./pages/Calendar"));

function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

const App = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const Query = useQuery();

  const IsSideBarOpen = useSelector((state) => state.UI.isSideBarOpen);
  const ShowMessagesOnly = useSelector((state) => state.UI.ShowMessagesOnly);
  const IsAuth = useSelector((state) => state.User.IsAuth);
  const UserData = useSelector((state) => state.User.UserData);

  const NotesFromRedux = useSelector((state) => state.Msg.Notes);

  useEffect(() => {
    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.response.data.error.code === 401) {
          dispatch(LogoutSuccess());
          toast.dismiss();
          toast.error("Session Expired");
        } else {
        }

        return Promise.reject(error);
      }
    );
  }, []);

  useEffect(() => {
    if (Query.get("goToMessage")) {
      dispatch(setShowMessagesOnly());
    }
  }, []);

  useEffect(() => {
    if (IsAuth) {
      dispatch(Actions.GetAllPrograms());
      dispatch(Actions.GetAllprogramsJoinedByTheLoggedInUser());
    }
  }, [IsAuth]);

  const FallBackUi = () => {
    return (
      <div
        style={{ height: "100vh", width: "100%" }}
        className="d-flex justify-content-center align-items-center"
      >
        <CircularProgress />
      </div>
    );
  };

  if (location.pathname === "/iframe/ICD10Library")
    return (
      <Suspense fallback={<FallBackUi />}>
        <div style={{ height: "100vh", width: "100%", overflowY: "scroll" }}>
          <ICD10Library />
        </div>
      </Suspense>
    );
  
  if (location.pathname === "/iframe/Calender")
    return (
      <Suspense fallback={<FallBackUi />}>
        <div style={{ height: "100vh", width: "100%", overflowY: "scroll" }}>
          <Calendar />
        </div>
      </Suspense>
    );

  if (location.pathname === "/iframe/RVCSurveyPopup")
    return (
      <Suspense fallback={<FallBackUi />}>
        <div style={{ height: "100vh", width: "100%", overflowY: "scroll" }}>
          <SurveyPopup />
        </div>
      </Suspense>
    );

  if (location.pathname === "/iframe/allPrograms")
    return (
      <Suspense fallback={<FallBackUi />}>
        <div style={{ height: "100vh", width: "100%", overflowY: "scroll" }}>
          <AllProgramsForEngage />
        </div>
      </Suspense>
    );

  if (location.pathname === "/fitbit-health")
    return (
      <Suspense fallback={<FallBackUi />}>
        <div style={{ height: "100vh", width: "100%", overflowY: "scroll" }}>
          <FitBitHealth />
        </div>
      </Suspense>
    );

  if (location.pathname === "/iframe/RTMBilling")
    return (
      <Suspense fallback={<FallBackUi />}>
        <div style={{ height: "100vh", width: "100%", overflowY: "scroll" }}>
          <RTMBillingPage />
        </div>
      </Suspense>
    );

  if (location.pathname === "/iframe/RtmPatientsDashboard")
    return (
      <Suspense fallback={<FallBackUi />}>
        <div style={{ height: "100vh", width: "100%", overflowY: "scroll" }}>
          <RtmPatientsDashboard />
        </div>
      </Suspense>
    );

  if (location.pathname === "/iframe/RTMEpisodes")
    return (
      <Suspense fallback={<FallBackUi />}>
        <div
          id="RTMContainer"
          style={{ height: "100vh", width: "100%", overflowY: "scroll" }}
        >
          <RTMEpisodesPage
            pmid={Query.get("pmid")}
            patientName={Query.get("patientName")}
          />
        </div>
      </Suspense>
    );

  if (location.pathname === "/compliance-page")
    return (
      <Suspense fallback={<FallBackUi />}>
        <div style={{ height: "100vh", width: "100%", overflowY: "scroll" }}>
          <WrapperForOnlyCompliance AccessCode={Query.get("accessCode")} />
        </div>
      </Suspense>
    );

  if (location.pathname === "/survey-page")
    return (
      <Suspense fallback={<FallBackUi />}>
        <div style={{ height: "100vh", width: "100%", overflowY: "scroll" }}>
          <WrapperForOnlySurvey AccessCode={Query.get("accessCode")} />
        </div>
      </Suspense>
    );

  if (location.pathname === "/material-page")
    return (
      <Suspense fallback={<FallBackUi />}>
        <div style={{ height: "100vh", width: "100%", overflowY: "scroll" }}>
          <WrapperForOnlyMaterial AccessCode={Query.get("accessCode")} />
        </div>
      </Suspense>
    );

  if (location.pathname === "/hep-page")
    return (
      <Suspense fallback={<FallBackUi />}>
        <div style={{ height: "100vh", width: "100%", overflowY: "scroll" }}>
          <WrapperForHepWithoutLogin AccessCode={Query.get("accessCode")} />
        </div>
      </Suspense>
    );
  if (location.pathname === "/activity-page")
    return (
      <Suspense fallback={<FallBackUi />}>
        <div style={{ height: "100vh", width: "100%", overflowY: "scroll" }}>
          <WrapperForActivityWithoutLogin
            AccessCode={Query.get("accessCode")}
          />
        </div>
      </Suspense>
    );

  if (location.pathname === "/notes/expanded")
    return (
      <Suspense fallback={<FallBackUi />}>
        <Notes IsLoadingNotes={false} Notes={NotesFromRedux} Expand={true} />
      </Suspense>
    );

  if (location.pathname === "/iframe/documents")
    return (
      <Suspense fallback={<FallBackUi />}>
        <Documents />
      </Suspense>
    );

  if (location.pathname === "/iframe/manageClients")
    return (
      <Suspense fallback={<FallBackUi />}>
        <ManageClients />
      </Suspense>
    );

  if (location.pathname === "/employeeRegistration")
    return (
      <Suspense fallback={<FallBackUi />}>{<EmployeeRegistration />}</Suspense>
    );

  if (location.pathname === "/employeeSelfRegistration")
    return (
      <Suspense fallback={<FallBackUi />}>
        {<EmployeeSelfRegistration />}
      </Suspense>
    );

  if (!IsAuth)
    return (
      <>
        <Suspense fallback={<FallBackUi />}>
          <Routes>
            <Route path="/" exact element={<Login />} />
            <Route path="/forgetPassword" element={<ForgetPassword />} />
            <Route path="*" element={<Navigate to="/" />} />
          </Routes>
        </Suspense>
      </>
    );

  if (ShowMessagesOnly)
    return (
      <Suspense fallback={<FallBackUi />}>
        <WrapperForMessagesOnlyWithLogin />
      </Suspense>
    );

  return (
    <div
      className="RootApp"
      style={{
        width: "100%",
        height: "100vh",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        className="NavbarContainer"
        style={{
          width: "100%",
          height: "70px",
          position: "fixed",
          zIndex: 1000,
          background: "white",
        }}
      >
        <Navbar />
      </div>
      <div
        className="MainApp"
        style={{ flex: 1, display: "flex", overflowY: "scroll" }}
      >
        <aside
          className="SideBarContainer"
          style={{
            height: "calc(100% - 70px)",
            width: IsSideBarOpen ? "250px" : "60px",
            marginTop: "70px",
            position: "fixed",
            // overflow: "hidden"
          }}
        >
          <Sidebar />
        </aside>
        <div
          style={{
            height: "100%",
            width: `calc(100% - ${IsSideBarOpen ? "250px" : "60px"})`,
            marginLeft: IsSideBarOpen ? "250px" : "60px",
            marginTop: "70px",
          }}
        >
          <div
            style={{
              height: "100%",
              width: "100%",
              backgroundColor: "#f8fafd",
            }}
          >
            {/* {UserData.isActive == 0 ? <ChangePasswordDialog /> : null} */}
            <Suspense fallback={<FallBackUi />}>
              <Routes>
                <Route path="/" exact element={<Navigate to="/dashboard" />} />
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/squat-details" element={<SquatDetail />} />
                <Route path="/kemtai-player" element={<KemtaiPlayer />} />
                <Route path="/squat-result" element={<SquatResult />} />
                <Route path="/messages" element={<Messages />} />
                <Route path="/documents" element={<Documents />} />
                <Route path="/manageClients" element={<ManageClients />} />
                <Route
                  path="/employeeRegistration"
                  element={<EmployeeRegistration />}
                />
                <Route
                  path="/employeeSelfRegistration"
                  element={<EmployeeSelfRegistration />}
                />
                <Route path="/programs" element={<Programs />} />
                <Route path="/program/:id" element={<ProgramDetails />} />
                <Route
                  path="/ongoing-program/:id"
                  element={<MyCurrentProgram />}
                />
                <Route path="/myProfile" element={<MyProfile />} />
                <Route path="/providers" element={<Providers />} />
                <Route path="/goalsAndOutcomes" element={<GoalsAnOutcomes />} />
                <Route
                  path="/surveyAndMaterial"
                  element={<SurveyAndMaterial />}
                />
                <Route path="/hep" element={<HEP />} />
                <Route path="fitbit" element={<FitbitHealthData />} />
                {/* <Route
                  path="/requestVideoConsultation"
                  element={<RequestVideoConsultation />}
                /> */}
                <Route
                  path="/book-consultation"
                  element={<BookConsultation />}
                />
                <Route path="/upcomingVisit" element={<UpcomingVisits />} />
                <Route path="*" element={<Navigate to="/" />} />
              </Routes>
            </Suspense>
          </div>
        </div>
      </div>
    </div>
  );
};

App.propTypes = {};

export default App;
